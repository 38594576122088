/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0; }
  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/****************************************
*****************************************
DROPDOWN EXTENSION
This extension provides the use of
dropdowns in navigation.
*****************************************
****************************************/
/*=== DROPDOWN ========================*/
.navigation .nav-child {
  min-width: 180px;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  list-style: none;
  z-index: 98;
  white-space: nowrap; }

.navigation-portrait .nav-child {
  width: 100%;
  position: static;
  left: 0; }

.navigation .nav-child .nav-child {
  left: 100%; }

.navigation .menu > li > .nav-child {
  border-top: solid 1px #f0f0f0; }

/* Dropdown items */
.navigation .nav-child > li {
  width: 100%;
  float: left;
  clear: both;
  position: relative;
  text-align: left; }

.navigation .nav-child > li > a {
  width: 100%;
  padding: 16px 20px;
  display: inline-block;
  text-decoration: none;
  float: left;
  font-size: 13px;
  color: #70798b;
  background-color: #fdfdfd;
  transition: color .3s, background .3s; }

.navigation .nav-child > li:hover > a,
.navigation .nav-child > .focus > a {
  color: #A0D468; }

/* Dropdown to left */
.navigation .nav-child {
  right: 0; }

.navigation .nav-child > li > .nav-dropdown-left {
  left: auto;
  right: 100%; }

.navigation-landscape .nav-dropdown-left > li > a {
  text-align: right; }

/* Dropdown indentation (until the fifth level) */
.navigation-portrait .nav-child > li > a {
  padding: 12px 20px 12px 30px; }

.navigation-portrait .nav-child > li > ul > li > a {
  padding-left: 50px; }

.navigation-portrait .nav-child > li > ul > li > ul > li > a {
  padding-left: 70px; }

.navigation-portrait .nav-child > li > ul > li > ul > li > ul > li > a {
  padding-left: 90px; }

.navigation-portrait .nav-child > li > ul > li > ul > li > ul > li > ul > li > a {
  padding-left: 110px; }

/*=== SUBMENU INDICATORS ==============*/
.navigation .nav-child .submenu-indicator {
  right: 15px;
  top: 16px;
  position: absolute; }

.navigation-portrait .submenu-indicator {
  right: 0;
  top: 0; }

.navigation .nav-child .submenu-indicator .submenu-indicator-chevron {
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.navigation-portrait .nav-child .submenu-indicator .submenu-indicator-chevron {
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.navigation .nav-child > li:hover > a .submenu-indicator-chevron,
.navigation .nav-child > .focus > a .submenu-indicator-chevron {
  border-color: transparent #A0D468 #A0D468 transparent; }

/* Submenu indicator to left */
.navigation-landscape .nav-dropdown-left .submenu-indicator {
  left: 10px; }

.navigation-landscape .nav-dropdown-left .submenu-indicator .submenu-indicator-chevron {
  -ms-transform: rotate(135deg);
  transform: rotate(135deg); }

/*
Item name: Super Responsive Navigation
Item Url:
License: https://codecanyon.net/licenses/standard
*/
/****************************************
*****************************************
CORE STYLES
*****************************************
****************************************/
/*=== NAVIGATION ======================*/
.navigation,
.navigation * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; }

.navigation {
  width: 100%;
  /*height: 70px;*/
  display: table;
  position: relative;
  font-family: inherit;
  background-color: #fff; }

.navigation-portrait {
  height: 48px; }

/* Fixed navigation */
.navigation-fixed {
  position: fixed;
  top: 0;
  left: 0; }

/* Hidden navigation */
.navigation-hidden {
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important; }

/*=== RIGHT ALIGMENT FOR ELEMENTS =======*/
.align-to-right {
  float: right; }

/*=== NAVIGATION HEADER =================*/
.nav-header {
  float: left; }

/* Header in hidden navigation */
.navigation-hidden .nav-header {
  display: none; }

/*=== BRAND TEXT ========================*/
.nav-brand {
  line-height: 70px;
  padding: 0 15px;
  color: #70798b;
  font-size: 24px;
  text-decoration: none !important; }

.nav-brand:hover,
.nav-brand:focus {
  color: #70798b; }

.navigation-portrait .nav-brand {
  font-size: 18px;
  line-height: 48px; }

/*=== IMAGE LOGO ========================*/
.nav-logo > img {
  height: 48px;
  margin: 11px auto;
  padding: 0 15px;
  float: left; }

.nav-logo:focus > img {
  outline: initial; }

.navigation-portrait .nav-logo > img {
  height: 36px;
  margin: 6px auto 6px 15px;
  padding: 0; }

/*=== TOGGLE BUTTON =====================*/
.nav-toggle {
  width: 30px;
  height: 30px;
  padding: 6px 2px 0;
  position: absolute;
  top: 50%;
  margin-top: -14px;
  right: 15px;
  display: none;
  cursor: pointer; }
  .nav-toggle:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 16px;
    width: 31px;
    height: 4px;
    background-color: #70798b;
    border-radius: 0px;
    box-shadow: 0 0.6em 0 0 #70798b; }
  .nav-toggle:after {
    content: 'MENU';
    position: absolute;
    color: #70798b;
    font-size: 10px;
    top: 33px;
    left: 0px;
    padding-top: 2px;
    border-top: 4px solid #70798b;
    font-weight: bold; }

.navigation-portrait .nav-toggle {
  display: block; }

.front .nav-toggle:after {
  border-color: #fff; }

/*=== NAVIGATION MENUS WRAPPER ===========*/
/*=== (OFFCANVAS PANEL ON MOBILE) ========*/
.navigation-portrait .nav-menus-wrapper {
  width: 100%;
  height: 100%;
  top: 0;
  left: -400px;
  position: fixed;
  background-color: #eb6e12;
  z-index: 20000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition-duration: .8s;
  transition-timing-function: ease; }

/* Offcanvas on right side */
.navigation-portrait .nav-menus-wrapper.nav-menus-wrapper-right {
  left: auto;
  right: -400px; }

/* Offcanvas open */
.nav-menus-wrapper {
  visibility: hidden; }

.nav-menus-wrapper.nav-menus-wrapper-open {
  left: 0;
  visibility: visible; }

.nav-menus-wrapper.nav-menus-wrapper-right.nav-menus-wrapper-open {
  right: 0; }

/* Close button for Offcanvas */
.nav-menus-wrapper-close-button {
  width: 30px;
  height: 40px;
  margin: 10px 7px;
  display: none;
  float: right;
  color: #fff;
  font-size: 26px;
  cursor: pointer; }

.navigation-portrait .nav-menus-wrapper-close-button {
  display: block; }

/*=== MENUS (FIRST LEVEL) ===============*/
.navigation .menu {
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: normal; }

.navigation-portrait .menu {
  width: 100%; }

/* Centered menu */
.navigation-landscape .menu.nav-menu-centered {
  float: none;
  text-align: center; }

/* Menu itens */
.navigation .menu > li {
  display: inline-block;
  float: left;
  text-align: left; }

.navigation-portrait .menu > li {
  width: 100%;
  position: relative;
  border-top: solid 1px #f19063; }

.navigation-portrait .menu > li:last-child {
  border-bottom: solid 1px #f19063; }

.navigation .menu + .navigation .menu > li:first-child {
  border-top: none; }

/* Menu items in centered menus */
.navigation-landscape .menu.nav-menu-centered > li {
  float: none; }

/* Menu links */
.navigation .menu > li > a {
  height: 70px;
  padding: 26px 15px;
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  color: #fff;
  transition: color .3s, background .3s; }

.navigation-portrait .menu > li > a {
  height: auto; }

.navigation .menu > li:hover > a,
.navigation .menu > .active > a,
.navigation .menu > .focus > a {
  color: #eec9b8; }

.navigation-portrait .menu > li > a {
  width: 100%;
  padding: 12px 15px 12px 26px; }

/* Menu item with icons and text */
.navigation .menu > li > a > i,
.navigation .menu > li > a > [class*='ion-'] {
  width: 18px;
  height: 16px;
  line-height: 16px;
  -ms-transform: scale(1.4);
  transform: scale(1.4); }

.navigation .menu > li > a > [class*='ion-'] {
  width: 16px;
  display: inline-block;
  transform: scale(1.8); }

/* Menu item with social icons */
.navigation-portrait .menu.nav-menu-social {
  width: 100%;
  text-align: center; }

.navigation .menu.nav-menu-social > li {
  text-align: center;
  float: none;
  border: none !important; }

.navigation-portrait .menu.nav-menu-social > li {
  width: auto; }

.navigation .menu.nav-menu-social > li > a > [class*='ion-'] {
  font-size: 12px; }

.navigation .menu.nav-menu-social > li > a > .fa {
  font-size: 14px; }

.navigation-portrait .menu.nav-menu-social > li > a {
  padding: 15px; }

/*=== SUBMENU INDICATORS ================*/
.submenu-indicator {
  margin-left: 6px;
  margin-top: 6px;
  float: right;
  transition: all .3s; }

.navigation-portrait .submenu-indicator {
  width: 54px;
  height: 44px;
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  z-index: 20000; }

/* Chevron icon */
.submenu-indicator-chevron {
  height: 6px;
  width: 6px;
  display: block;
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: transparent #70798b #70798b transparent;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: border .3s; }

.navigation-portrait .submenu-indicator-chevron {
  position: absolute;
  top: 18px;
  left: 24px; }

.navigation .menu > li:hover > a .submenu-indicator-chevron,
.navigation .menu > .active > a .submenu-indicator-chevron,
.navigation .menu > .focus > a .submenu-indicator-chevron {
  border-color: transparent #A0D468 #A0D468 transparent; }

/* Indicator to up */
.navigation-portrait .submenu-indicator.submenu-indicator-up {
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg); }

/*=== OVERLAY PANEL =====================*/
.nav-overlay-panel {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: none;
  z-index: 19999; }

/* Prevent scrolling on body */
.no-scroll {
  width: 100%;
  height: 100%;
  overflow: hidden; }

/****************************************
*****************************************
ADITIONAL STYLES
Check the features below and delete the
styles that you are not using
*****************************************
****************************************/
/*=== SEARCH PANEL ====================*/
.nav-search {
  height: 70px;
  float: right;
  z-index: 19998; }

.navigation-portrait .nav-search {
  height: 48px;
  padding: 0 10px;
  margin-right: 52px; }

/* Search in hidden navigation */
.navigation-hidden .nav-search {
  display: none; }

/* Search button */
.nav-search-button {
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  background-color: #fbfcfd; }

/* Search icon */
.nav-search-icon {
  width: 14px;
  height: 14px;
  margin: 2px 8px 8px 4px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  color: #70798b;
  text-align: left;
  text-indent: -9999px;
  border: 2px solid;
  border-radius: 50%;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: color .3s; }

.nav-search-icon:after,
.nav-search-icon:before {
  content: '';
  pointer-events: none; }

.nav-search-icon:before {
  width: 2px;
  height: 11px;
  top: 11px;
  position: absolute;
  left: 50%;
  border-radius: 0 0 1px 1px;
  box-shadow: inset 0 0 0 32px;
  transform: translateX(-50%); }

.nav-search-button:hover .nav-search-icon {
  color: #A0D468; }

.navigation-portrait .nav-search-button {
  width: 50px;
  height: 48px;
  line-height: 46px;
  font-size: 22px; }

/* Form */
.nav-search > form {
  width: 100%;
  height: 100%;
  padding: 0 auto;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 99; }

.nav-search-inner {
  width: 70%;
  height: 70px;
  margin: auto;
  display: table; }

.navigation-portrait .nav-search-inner {
  height: 48px; }

.nav-search-inner input[type=text],
.nav-search-inner input[type=search] {
  height: 70px;
  width: 100%;
  margin: 0;
  padding: 0 12px;
  font-size: 26px;
  text-align: center;
  color: #70798b;
  outline: none;
  line-height: 70px;
  border: none;
  background-color: transparent;
  transition: all .3s; }

.navigation-portrait .nav-search-inner input[type=text],
.navigation-portrait .nav-search-inner input[type=search] {
  height: 48px;
  font-size: 18px;
  line-height: 48px; }

/* Close button for search form */
.nav-search-close-button {
  width: 28px;
  height: 28px;
  display: block;
  position: absolute;
  right: 20px;
  top: 20px;
  line-height: normal;
  color: #70798b;
  font-size: 20px;
  cursor: pointer;
  text-align: center; }

.navigation-portrait .nav-search-close-button {
  top: 10px;
  right: 14px; }

/*=== NAVIGATION BUTTON ================*/
.nav-button {
  margin: 18px 15px 0;
  padding: 8px 14px;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  background-color: #A0D468;
  transition: opacity .3s; }

.nav-button:hover,
.nav-button:focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.85; }

.navigation-portrait .nav-button {
  width: calc(100% - 52px);
  margin: 17px 26px; }

/*=== NAVIGATION TEXT ==================*/
.nav-text {
  margin: 25px 15px;
  display: inline-block;
  color: #70798b;
  font-size: 14px; }

.navigation-portrait .nav-text {
  width: calc(100% - 52px);
  margin: 12px 26px 0; }

.navigation-portrait .nav-text + ul {
  margin-top: 15px; }

/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #80bdff; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-webkit-slider-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-moz-range-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-ms-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/*@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/print'; */
html, body {
  height: 100%; }

body {
  color: #222;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.4;
  overflow-x: hidden; }

a:active, a:focus, a:hover {
  text-decoration: none; }

img {
  max-width: 100%; }

.max_width {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem; }
  @media (min-width: 768px) {
    .max_width {
      padding: 0 2rem; } }

.main_article_area {
  padding: 2rem 0 4rem; }
  .main_article_area h3 {
    margin-top: 3rem; }
  .main_article_area li {
    margin-bottom: 0.25rem; }

.sidebar_area {
  padding: 2rem 0 4rem; }

.btn {
  display: inline-block;
  padding: 1rem 2rem;
  transition-duration: 0.15s;
  transition-property: color background-color border-color box-shadow;
  transition-timing-function: ease-in-out;
  border: 1px solid transparent;
  border-radius: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none; }
  .btn:active, .btn:focus, .btn:hover {
    text-decoration: none; }
  .btn [class*=fa] {
    vertical-align: middle; }
  .btn-primary {
    background-color: #3189c2;
    color: #fff; }
    .btn-primary:active, .btn-primary:focus, .btn-primary:hover {
      background-color: #276c99;
      color: #fff; }
  .btn-light {
    border: 1px solid #3189c2;
    background: none; }
    .btn-light:active, .btn-light:focus, .btn-light:hover {
      border: 1px solid #276c99;
      background: none; }
  .btn-secondary, .btn-warning {
    border-color: transparent;
    background-color: #f16522;
    color: #fff; }
    .btn-secondary:active, .btn-secondary:focus, .btn-secondary:hover, .btn-warning:active, .btn-warning:focus, .btn-warning:hover {
      border-color: #f16522;
      background-color: #d34d0d;
      color: #fff; }

.o-iframe {
  position: relative;
  max-width: 100%;
  height: auto;
  padding-bottom: 56.25%;
  overflow: hidden; }
  .o-iframe iframe,
  .o-iframe object,
  .o-iframe .map,
  .o-iframe embed {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%; }

.address_box {
  background: #fff;
  padding: 18px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  position: relative;
  border-left: 4px solid #0b57a5;
  margin-bottom: 30px;
  min-height: 160px; }

.address_box h4 {
  color: #f16522;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold; }

.address_box .address_line {
  height: 1px;
  background: #dfdfdf;
  width: 60px;
  margin: 10px 0 10px 0; }

.address_box svg {
  color: #f16522; }

.address_box p {
  margin-bottom: 5px; }

.address_box a {
  margin-top: 5px;
  display: inline-block;
  color: #242323;
  font-weight: bold;
  font-size: 14px;
  vertical-align: text-bottom; }

.ausmap_area {
  padding: 50px 0; }

.ausmap_area h2 {
  font-size: 32px;
  line-height: 38px;
  margin-top: 30px;
  font-weight: 300; }

.down_line {
  height: 1px;
  background: #dfdfdf;
  max-width: 150px;
  margin: 15px 0; }

.ausmap_area ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px; }

.ausmap_area ul li {
  padding-left: 30px;
  background: url(../images/arrow-bullet.png) no-repeat;
  background-position: center left;
  margin-bottom: 10px;
  font-size: 14px; }

.ausmap_area .btn {
  color: #fff;
  line-height: 40px;
  text-transform: uppercase; }

.ausmap_area .button_area {
  margin-top: 40px; }

/*** Banner area ***/
.main_banner {
  color: #fff;
  height: calc(100vh - 490px);
  min-height: 450px; }

.front .main_banner {
  height: calc(100vh - 40px);
  min-height: 520px; }

.main_banner_wrapper {
  position: relative;
  z-index: 1;
  pointer-events: none;
  max-width: 82%;
  margin: 0 auto; }

.main_banner .text_area {
  margin-top: 20%; }

.front .main_banner .text_area {
  margin-top: 55vh; }

.main_banner .text_area h5 {
  text-transform: uppercase;
  font-size: 30px;
  line-height: 34px;
  margin-bottom: 0px;
  font-weight: 600; }

.main_banner .text_area h3 {
  text-transform: uppercase;
  font-size: 64px;
  line-height: 70px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 30px; }

.main_banner .text_area h4 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0px;
  font-weight: 600;
  margin-bottom: 10px; }

.main_banner .text_area .btn_area {
  margin-top: 20px; }

.main_banner .text_area .btn_area .btn {
  text-transform: uppercase;
  color: #fff;
  margin-right: 10px;
  font-size: 14px;
  line-height: 24px;
  pointer-events: initial; }

.main_banner .text_area .btn_area .btn-light {
  border-color: #fff; }

.rest_of_body {
  position: relative;
  z-index: 1;
  background: #fff; }

.main_component_area .office_addresses .row:nth-child(1) .col-4:nth-child(1) .address_box {
  border-color: #ea5c8a; }

.main_component_area .office_addresses .row:nth-child(1) .col-4:nth-child(2) .address_box {
  border-color: #e4313c; }

.main_component_area .office_addresses .row:nth-child(1) .col-4:nth-child(3) .address_box {
  border-color: #93286e; }

.main_component_area .office_addresses .row:nth-child(1) .col-4:nth-child(4) .address_box {
  border-color: #ea5c8a; }

.main_component_area .office_addresses .row:nth-child(2) .col-4:nth-child(1) .address_box {
  border-color: #4b348f; }

.main_component_area .office_addresses .row:nth-child(2) .col-4:nth-child(2) .address_box {
  border-color: #4b348f; }

.main_component_area .office_addresses .row:nth-child(2) .col-4:nth-child(3) .address_box {
  border-color: #875173; }

.main_component_area .office_addresses .row:nth-child(2) .col-4:nth-child(4) .address_box {
  border-color: #e4313c; }

.main_component_area .office_addresses .row:nth-child(3) .col-4:nth-child(1) .address_box {
  border-color: #4b348f; }

.main_component_area .office_addresses .row:nth-child(3) .col-4:nth-child(2) .address_box {
  border-color: #93286e; }

.main_component_area .office_addresses .row:nth-child(3) .col-4:nth-child(3) .address_box {
  border-color: #875173; }

.main_component_area .office_addresses .row:nth-child(3) .col-4:nth-child(4) .address_box {
  border-color: #4b348f; }

.main_component_area .office_addresses .row:nth-child(4) .col-4:nth-child(1) .address_box {
  border-color: #875173; }

.main_component_area .office_addresses .row:nth-child(4) .col-4:nth-child(2) .address_box {
  border-color: #4b348f; }

.main_component_area .office_addresses .row:nth-child(4) .col-4:nth-child(3) .address_box {
  border-color: #93286e; }

.main_component_area .office_addresses .row:nth-child(4) .col-4:nth-child(4) .address_box {
  border-color: #875173; }

.main_component_area .office_addresses .row:nth-child(5) .col-4:nth-child(1) .address_box {
  border-color: #ea5c8a; }

.main_component_area .office_addresses .row:nth-child(5) .col-4:nth-child(2) .address_box {
  border-color: #4b348f; }

.main_component_area .office_addresses .row:nth-child(5) .col-4:nth-child(3) .address_box {
  border-color: #e4313c; }

.main_component_area .office_addresses .row:nth-child(5) .col-4:nth-child(4) .address_box {
  border-color: #93286e; }

.contact-us .page-header h2 {
  text-align: center;
  font-size: 42px;
  line-height: 48px;
  text-transform: uppercase;
  margin-bottom: 50px;
  position: relative; }

.contact-us .page-header h2 span {
  display: inline-block;
  background: #eff3f5;
  padding: 10px;
  position: relative; }

.contact-us .page-header h2 i {
  height: 1px;
  width: 100%;
  background: #aaa;
  position: absolute;
  left: 0;
  top: 32px; }

.contact_area {
  padding: 50px 0;
  background: url("../images/contact-banner-gray.jpg") no-repeat;
  background-size: cover; }

.contact_area_bg {
  background: #fff;
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.28); }

.contact_form_wrapper input, .contact_form_wrapper select {
  border-radius: 0;
  border-top: none;
  border-right: none; }

.contact_form_wrapper .form-control {
  border: 1px solid #ced4da !important;
  padding: .375rem .75rem !important; }

.contact_form_wrapper .input-group-text {
  width: 40px; }

.contact_form_wrapper .row > div {
  margin-bottom: 16px; }

.contact_form_wrapper h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 30px; }

.inner_banner {
  background: url(../images/contact-main-banner.jpg) no-repeat center center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 150px;
  text-align: center;
  height: 364px; }

.inner_banner h1 {
  font-size: 42px;
  line-height: 48px;
  font-weight: 600; }

.main_content {
  position: relative;
  z-index: 1;
  background-color: #fff; }

.main_component_area {
  position: relative;
  padding: 50px 0;
  background: #fff;
  color: #242323;
  z-index: 1; }

.contact-us .main_component_area {
  background: #eff3f5; }

.page-header h2 {
  text-transform: uppercase; }

.image_holder {
  margin-top: 30px; }

.image_holder img {
  width: 100%; }

.main_article_area {
  background: #fff;
  padding: 100px; }
  .main_article_area .indentedChecklist {
    padding-left: 30px; }
    .main_article_area .indentedChecklist h6 {
      position: relative; }
    .main_article_area .indentedChecklist h6 .far {
      position: absolute;
      left: -30px;
      top: 4px; }
  .main_article_area p.emphasised {
    color: #999;
    display: block;
    padding-left: 15px;
    border-left: 1px solid #444;
    font-style: italic; }

.movePageUp .main_component_area {
  background: #ededed;
  padding: 0px; }

.movePageUp .main_component_area_wrapper {
  position: relative;
  top: -200px; }
  .movePageUp .main_component_area_wrapper h1 {
    color: #3189c2; }
  .movePageUp .main_component_area_wrapper h2 {
    color: #000;
    text-transform: uppercase; }
  .movePageUp .main_component_area_wrapper h4 {
    font-size: 1.2rem !important; }
  .movePageUp .main_component_area_wrapper h6 {
    font-size: 1.2rem !important;
    color: #f16522;
    line-height: 1.6rem; }
  .movePageUp .main_component_area_wrapper .sidebar_area {
    padding: 0px; }

.movePageUp .inner_banner_wrapper {
  display: none; }

.dashboard-container {
  max-width: 600px;
  padding: 30px 15px;
  margin: auto; }
  .dashboard-container .twitter-handle {
    font-size: 18px;
    color: #ccc;
    margin: 25px 0; }
  .dashboard-container a {
    margin-bottom: 15px; }
  .dashboard-container .btn-success {
    background: #e6720f;
    border-color: #e6720f; }
  .dashboard-container .btn-success:hover, .dashboard-container .btn-success:focus, .dashboard-container .btn-success:active {
    background: #c5630d;
    border-color: #c5630d; }
  .dashboard-container .btn-success:not(:disabled):not(.disabled):active, .dashboard-container .btn-success:not(:disabled):not(.disabled).active, .dashboard-container .show > .btn-success.dropdown-toggle {
    background: #c5630d;
    border-color: #c5630d; }
  .dashboard-container .btn-success:not(:disabled):not(.disabled):active:focus, .dashboard-container .btn-success:not(:disabled):not(.disabled).active:focus, .dashboard-container .show > .btn-success.dropdown-toggle:focus {
    box-shadow: none; }

.ebook_area {
  background: url(../images/blue-background-repeat-y-new.png) repeat-y;
  background-color: #39b3b8;
  color: #fff;
  padding: 40px 0;
  padding-bottom: 0px;
  position: relative; }

.ebook_area.reverse {
  background: url(../images/orange-background-repeat-y-new.png) repeat-y; }

.ebbok_area_wrapper {
  position: relative;
  z-index: 9; }

.ebook_area h2 {
  text-transform: uppercase;
  font-size: 42px;
  line-height: 46px;
  font-weight: 300; }

.ebook_area h5 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 20px; }

.icon_set {
  text-align: center; }

.icon_set h4 {
  font-size: 16px;
  line-height: 20px;
  margin-top: 5px; }

.findout_more {
  border-top: 1px solid #fff;
  text-align: center;
  padding: 20px;
  margin: 20px 0; }

.findout_more a {
  color: #fff; }

.ebook_bottom_area {
  background: #1e80ba;
  color: #fff;
  padding: 20px 0;
  width: 100%; }

.ebook_bottom_area .btn-light {
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff; }

.ebook_bottom_area strong {
  display: inline-block;
  padding: 0 20px; }

.ebook_bottom_area .btn-secondary {
  text-transform: uppercase;
  color: #f16522;
  border: 1px solid #fff;
  background: #fff; }

.ebook_bottom_area span {
  display: inline-block;
  max-width: 250px;
  vertical-align: top;
  margin-left: 10px; }

.calendar {
  margin: 0 0 60px;
  border: 1px solid #dfdfdf; }

.calendar header {
  padding: 0 52px;
  margin: 0 0 30px; }

.calendar header::after {
  content: "";
  display: table;
  clear: both; }

.calendar .total-events {
  margin: 42px 0 0;
  float: right;
  text-align: center;
  font-size: 16px;
  color: #9f9fad; }

.calendar .total-events strong {
  display: block;
  font-size: 31px;
  margin: 0 0 7px;
  line-height: 1;
  font-weight: 500;
  color: #e64614; }

.calendar h1 {
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 900;
  margin: 0 0 30px; }

.calendar .body {
  background: #fff;
  border-radius: 4px;
  overflow: hidden; }

.btn-more {
  display: block;
  padding: 14px;
  border-radius: 0 0 4px 4px; }

.sort {
  font-size: 15px; }

.sort a + a {
  margin-left: 83px; }

.sort i {
  font-size: 12px;
  margin-left: 10px; }

.sort em {
  display: none;
  font-style: normal; }

.event-item {
  position: relative;
  overflow: hidden;
  padding: 40px 0; }

.event-item + .event-item {
  border-top: 1px solid #f4f5f9; }

.single .event-item {
  border-bottom: 1px solid #f4f5f9; }

.single .event-item .frame {
  padding-right: 220px; }

.event-item .date {
  float: left;
  width: 153px;
  text-align: center;
  color: #a0a0ad;
  font-weight: 300;
  font-size: 23px;
  text-transform: uppercase; }

.event-item .date strong {
  display: block;
  font-weight: 300;
  font-size: 43px;
  line-height: 1;
  margin: 0 0 10px; }

.event-item .time {
  float: left;
  width: 190px;
  color: #a0a0ad;
  font-size: 15px; }

.event-item .h {
  display: inline-block;
  vertical-align: top; }

.event-item .sep {
  display: block;
  margin: 14px auto;
  height: 14px;
  width: 1px;
  background: #cfcfd5; }

.event-item .frame {
  overflow: hidden;
  padding-right: 50px; }

.event-item .frame:first-child {
  padding-left: 52px; }

.event-item h2 {
  font-size: 26px;
  font-weight: 500;
  margin: 2px 0 18px;
  color: #e64614;
  letter-spacing: 1.5px; }

.event-item h2 a {
  color: inherit; }

.event-item .meta {
  display: block;
  font-size: 16px;
  color: #959db7; }

.event-item .meta a {
  color: #555; }

.event-item .more {
  position: absolute;
  top: 50%;
  right: 48px;
  margin-top: -32px;
  color: #f4f5f9;
  font-size: 60px;
  line-height: 1;
  -webkit-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out; }

.event-item:hover .more {
  color: #e64614;
  right: 40px; }

.event-item .action {
  position: absolute;
  top: 0;
  right: 52px;
  width: 150px; }

.event-item .action::after {
  content: "";
  display: table;
  clear: both; }

.event-item .action .line {
  margin: 0 0 32px; }

.event-item .action .line::after {
  content: "";
  display: table;
  clear: both; }

.event-item .action .btn, .event-item .action .jcf-select {
  display: block; }

.event-item .price {
  float: left;
  margin: -4px 0;
  font-size: 31px;
  font-weight: 700;
  margin-left: -44px;
  text-align: right;
  width: 100px;
  color: #e64614; }

.event-item .count {
  float: right;
  margin: 0 10px 0 0;
  padding: 0 12px 0 0;
  text-align: center;
  width: 59px;
  border-left: 1px solid #dee1ec;
  font-size: 19px;
  line-height: 27px;
  font-weight: 700;
  color: #464c62;
  position: relative; }

.event-item .count .dec, .event-item .count .inc {
  position: absolute;
  right: 0;
  top: -5px;
  width: 12px;
  height: 12px;
  border: 6px solid transparent;
  content: "";
  border-color: transparent transparent #464c62 transparent; }

.event-item .count .dec {
  top: auto;
  bottom: -5px;
  border-color: #464c62 transparent transparent transparent; }

.filter_search_bar_area_wrap {
  margin-top: 60px; }

.filter_search_bar_area {
  margin-top: 40px;
  background: #fff; }
  .filter_search_bar_area .nav-pills .nav-link {
    border-radius: 0px; }
  .filter_search_bar_area .nav-link {
    color: #777;
    padding: 15px 20px;
    border-bottom: 1px solid #dfdfdf; }
    .filter_search_bar_area .nav-link i {
      float: right; }
  .filter_search_bar_area .nav-pills .nav-link.active, .filter_search_bar_area .nav-pills .show > .nav-link {
    background: #e6720f; }
  .filter_search_bar_area #filter_sidebar_tab {
    min-height: 320px;
    border-right: #ccc 1px solid; }
  .filter_search_bar_area #filter_sidebar_tabContent {
    padding: 10px 40px;
    min-height: 320px; }
    .filter_search_bar_area #filter_sidebar_tabContent h5 {
      text-transform: lowercase;
      margin-bottom: 20px; }
    .filter_search_bar_area #filter_sidebar_tabContent .row {
      margin: 0; }
  .filter_search_bar_area .filter_search_bar_area_footer {
    padding: 10px 20px;
    border-top: 1px solid #dfdfdf;
    text-align: right;
    display: none; }
    .filter_search_bar_area .filter_search_bar_area_footer .btn {
      border-radius: 0.25rem;
      padding: 5px 20px; }
      .filter_search_bar_area .filter_search_bar_area_footer .btn i {
        transform: scaleX(-1);
        margin-left: 5px;
        vertical-align: middle; }
  .filter_search_bar_area .filter_search_bar_area_main {
    display: none; }
  .filter_search_bar_area .filter_search_bar_area_header {
    padding: 20px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer; }
    .filter_search_bar_area .filter_search_bar_area_header h4 {
      margin-bottom: 0; }
    .filter_search_bar_area .filter_search_bar_area_header .filter_title_bar {
      font-size: 20px;
      font-weight: 600;
      float: left; }
      .filter_search_bar_area .filter_search_bar_area_header .filter_title_bar i {
        color: #e6720f;
        margin-right: 5px; }
      .filter_search_bar_area .filter_search_bar_area_header .filter_title_bar .right_side_icon {
        color: #333;
        margin-right: 5px;
        font-size: 16px; }
      .filter_search_bar_area .filter_search_bar_area_header .filter_title_bar .fa-chevron-down.right_side_icon {
        display: none; }
    .filter_search_bar_area .filter_search_bar_area_header .filter_count_bar {
      float: right;
      font-size: 16px;
      font-weight: normal; }
    .filter_search_bar_area .filter_search_bar_area_header .selected-attr {
      padding: 0;
      display: inline-block;
      margin-left: 20px;
      max-width: 80%; }
      .filter_search_bar_area .filter_search_bar_area_header .selected-attr a {
        background: #231f20;
        padding: 4px 15px;
        color: #dcc37e;
        border-radius: 5px;
        font-size: 14px;
        width: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        text-align: center; }
        .filter_search_bar_area .filter_search_bar_area_header .selected-attr a::before {
          content: "x";
          font-weight: 500;
          margin-left: 10px;
          float: right; }
      .filter_search_bar_area .filter_search_bar_area_header .selected-attr a.clear-all {
        color: #ffffff;
        background: #e6720f;
        font-weight: 600; }
        .filter_search_bar_area .filter_search_bar_area_header .selected-attr a.clear-all::before {
          display: none; }
  .filter_search_bar_area .show_hide {
    display: none; }

.show-more-btn {
  color: #e6720f; }
  .show-more-btn:hover {
    color: #e6720f; }

.filter_search_bar_area.active {
  border-bottom: #ccc 1px solid; }
  .filter_search_bar_area.active .filter_search_bar_area_main {
    display: block;
    border-right: #ccc 1px solid;
    border-left: #ccc 1px solid; }
  .filter_search_bar_area.active .filter_search_bar_area_footer {
    display: block; }
  .filter_search_bar_area.active .filter_search_bar_area_header .fa-chevron-down.right_side_icon {
    display: inline-block; }
  .filter_search_bar_area.active .filter_search_bar_area_header .fa-chevron-right.right_side_icon {
    display: none; }

.single_attr {
  margin-bottom: 10px; }

/* Hide the browser's default checkbox */
.customCbox {
  display: block;
  margin-left: 15px;
  margin-top: 1px;
  font-size: 14px; }

.customCbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 3px solid #e6720f; }

/* On mouse-over, add a grey background color */
.customCbox:hover input ~ .checkmark {
  background-color: #fff; }

/* When the checkbox is checked, add a blue background */
.customCbox input:checked ~ .checkmark {
  background-color: #e6720f; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.customCbox input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.customCbox .checkmark:after {
  left: 6px;
  top: 3px;
  width: 7px;
  height: 12px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.search_bar_area {
  padding: 20px 0;
  position: relative; }
  .search_bar_area input {
    width: 450px;
    height: 50px;
    box-sizing: border-box;
    padding: 10px;
    padding-left: 40px;
    border: 0px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    outline: none !important; }
  .search_bar_area input:focus {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 5px 0px rgba(131, 189, 228, 0.15); }
  .search_bar_area i.searchicon {
    position: absolute;
    top: 34px;
    left: 10px;
    font-size: 24px; }
  .search_bar_area i.clear_search {
    position: absolute;
    top: 34px;
    left: 420px;
    font-size: 24px;
    cursor: pointer; }
  .search_bar_area p {
    margin-top: 5px;
    font-size: 14px;
    line-height: 18px;
    color: #999; }
    .search_bar_area p strong {
      font-weight: bold; }

.membership_search_page .col-right {
  padding: 0 15px !important;
  padding-left: 15px !important; }

.footer {
  padding-top: 50px;
  padding-bottom: 20px;
  background: url("../images/footer-banner.jpg") no-repeat;
  background-size: cover;
  color: #fff; }

.footer a {
  color: #fff; }
  .footer a:active, .footer a:focus, .footer a:hover {
    color: #f16522; }

.footer h3 {
  color: #fff;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 15px 0; }

.footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.footer ul li {
  margin-bottom: 10px;
  width: 100%; }

.copyright {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 0; }

.header {
  background: #fdfdfd; }

.front .header_wrapper .row {
  margin: 0; }

.front .header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: none; }

.header_wrapper {
  padding: 20px; }

.front #navigation1 {
  padding: 10px 20px 0 0; }

.header_top {
  text-align: right;
  padding: 30px 0; }

.front .header .btn-light {
  display: none; }

.front .header .btn-primary {
  border: 1px solid #fff;
  color: #fff;
  background: none; }

.front .header .btn-primary:hover {
  color: #212529;
  background-color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.header_top .btn {
  text-transform: uppercase;
  min-width: 160px;
  margin-left: 10px; }

.logo-wrap {
  display: block;
  max-width: 250px;
  margin: auto; }
  .logo-wrap img {
    width: 100%; }

.navigation_buttton {
  font-size: 0.8rem; }

.body-wrapper {
  max-height: 100%; }

.main_banner {
  position: relative;
  vertical-align: top;
  height: calc(100vh - 40px);
  min-height: 520px; }

.home-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 40px);
  min-height: 520px; }
  .home-banner-item {
    width: 100%;
    height: calc(100vh - 40px);
    min-height: 520px;
    background-size: cover; }

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em; }

a.label:hover,
a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer; }

.label:empty {
  display: none; }

.label-default {
  background-color: #777; }

.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #5e5e5e; }

.label-primary {
  background-color: #428bca; }

.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: #3071a9; }

.label-success {
  background-color: #5cb85c; }

.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #449d44; }

.label-info {
  background-color: #5bc0de; }

.label-info[href]:hover,
.label-info[href]:focus {
  background-color: #31b0d5; }

.label-warning {
  background-color: #f0ad4e; }

.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: #ec971f; }

.label-danger {
  background-color: #d9534f; }

.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #c9302c; }

:root {
  --home: #FF8600;
  --travel: #6f42c1;
  --tech: #007bff;
  --fashion: #e83e8c;
  --sports: #dc3545;
  --lifestyle: #13a774;
  --food: #17a2b8;
  --market: #ffc107
  ; }

.color-home {
  color: var(--home) !important; }

.color-travel {
  color: var(--travel) !important; }

.color-tech {
  color: var(--tech) !important; }

.color-fashion {
  color: var(--fashion) !important; }

.color-sports {
  color: var(--sports) !important; }

.color-lifestyle {
  color: var(--lifestyle) !important; }

.color-food {
  color: var(--food) !important; }

.color-market {
  color: var(--market) !important; }

.bg-home {
  background-color: var(--home) !important; }

.bg-travel {
  background-color: var(--travel) !important; }

.bg-tech {
  background-color: var(--tech) !important; }

.bg-fashion {
  background-color: var(--fashion) !important; }

.bg-sports {
  background-color: var(--sports) !important; }

.bg-lifestyle {
  background-color: var(--lifestyle) !important; }

.bg-food {
  background-color: var(--food) !important; }

.bg-market {
  background-color: var(--market) !important; }

.news_article .entry-image {
  position: relative;
  text-align: center; }

.news_article .entry-image img {
  width: 100%; }

.news_article .entry-categories a {
  color: #fff !important;
  padding: 3px 5px;
  font-size: 10px;
  border-radius: 2px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  font-family: roboto,sans-serif; }

.news_article .entry-categories {
  position: absolute;
  bottom: 10px;
  left: 10px;
  text-transform: capitalize; }

.news_article .entry-title h3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin: 20px 0;
  color: #444; }

.news_article .entry-title a {
  color: #333;
  -webkit-transition: color .3s ease;
  -o-transition: color .3s ease;
  transition: color .3s ease; }

.news_article .entry-title a:hover {
  color: #ff8600; }

.news_article .entry-meta {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.news_article .entry-meta li {
  float: left;
  font-size: 13px;
  line-height: 14px;
  margin: 0 10px 15px 0;
  color: #999; }

.news_article .entry-meta li:before {
  content: '';
  margin-bottom: 3px;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #ccc;
  display: inline-block;
  margin-right: 10px;
  opacity: .5; }

.news_article .entry-meta li:first-child::before {
  display: none; }

.news_article .entry-meta li a {
  color: #aaa;
  margin-left: 2px; }

.news_article .entry-meta li a:hover {
  color: #ff8600; }

.news_article .entry-content {
  color: #555;
  font-size: 14px;
  line-height: 22px; }

.news_article.spost {
  padding-top: 20px;
  border-top: 1px dashed #e5e5e5;
  margin-top: 20px; }
  .news_article.spost .entry-image {
    float: left;
    margin: 0 15px 0 0;
    text-align: center; }
  .news_article.spost .entry-image a, .news_article.spost .entry-image img {
    width: 48px;
    height: 48px; }
  .news_article.spost .entry-title h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 600; }
  .news_article.spost .entry-meta {
    margin: 8px 0 0 0; }

.double-line, .line {
  clear: both;
  position: relative;
  width: 100%;
  margin: 60px 0;
  border-top: 1px solid #eee; }

.line-xs {
  margin: 4px 0 20px;
  border-bottom: 3px solid #eee; }

.line-xs.line-home {
  border-bottom-color: var(--home) !important; }

.line-xs:after {
  top: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #eee;
  border-width: 8px;
  margin-left: -8px;
  z-index: 1;
  background: 0 0; }

.line-xs.line-home:after {
  border-top-color: var(--home) !important; }

.sidebar_title {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase; }

.latest_newsflash {
  padding: 30px 0; }
  .latest_newsflash .news_article.spost:first-child {
    padding-top: 20px;
    border-top: none;
    margin-top: 0px; }

.toggle_radios_popup {
  text-align: center; }

.toggle_radio_a_popup {
  display: inline-block;
  color: #333;
  vertical-align: text-top;
  text-transform: uppercase; }

.toggle_radios_popup span {
  color: #aaa;
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
  margin: 0 20px;
  vertical-align: sub; }

.toggle_radio_a_popup:hover,
.toggle_radio_a_popup:focus {
  text-decoration: none; }

.toggle_radio_a_popup i {
  color: #277532;
  font-size: 18px;
  margin-right: 10px; }

.login_guest_form_popup {
  margin-top: 20px; }

.login_guest_form_popup input[type="text"] {
  border-radius: 0;
  width: 100%;
  max-width: 300px;
  height: 30px; }

.login_guest_form_popup input[type="password"] {
  height: 30px; }

.login_guest_form_popup input[type="text"].error,
.login_guest_form_popup .signin_div_popup input.error {
  border-color: #E02828; }

.login_guest_form_popup .signin_div_popup {
  text-align: left;
  margin-top: 10px;
  display: none; }

.login_guest_form_popup .error_div_popup {
  margin: 10px 0;
  text-align: center;
  display: none;
  color: #E02828; }

.error_div_spopup {
  margin: 10px 0;
  text-align: center;
  color: #E02828; }

.login_guest_form_popup .warning_div_popup {
  margin: 10px 0;
  display: none;
  color: #c09853; }

.login_guest_form_popup .signin_div_popup input {
  border-radius: 0; }

.login_guest_form_popup .signin_div_popup .forgot {
  display: block;
  margin-top: 10px; }

.login_guest_area_popup {
  display: table;
  margin: 0 auto; }

#popup_registration_form {
  text-align: center; }

.registration_div_popup input {
  border-radius: 0;
  height: 40px;
  box-sizing: border-box;
  width: 48%; }

.registration_div_popup select {
  border-radius: 0;
  height: 40px;
  width: 48%;
  box-sizing: border-box; }

.registration_div_popup .full_length {
  width: 97%; }

.registration_div_popup .error {
  background: rgba(255, 81, 76, 0.25) !important; }

.blackLogo {
  display: none; }

.blackContent .whiteLogo {
  display: none !important; }

.blackContent .blackLogo {
  display: block; }

.blackContent .nav-header .btn-primary {
  border: 1px solid #000 !important;
  background: none;
  color: #000 !important; }
  .blackContent .nav-header .btn-primary:active, .blackContent .nav-header .btn-primary:focus, .blackContent .nav-header .btn-primary:hover {
    color: #212529;
    background-color: #fff !important;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.blackContent .navigation_wrapper .nav-header .nav-toggle {
  color: #000;
  cursor: pointer; }
  .blackContent .navigation_wrapper .nav-header .nav-toggle:after {
    color: #000 !important;
    border-color: #000; }
  .blackContent .navigation_wrapper .nav-header .nav-toggle:before {
    background-color: #000 !important;
    box-shadow: 0 0.60em 0 0 #000 !important; }

.blackContent .main_banner_wrapper {
  color: #000 !important; }

.hc-offcanvas-nav.nav-levels-overlap ul li.members-only-resources {
  background: #ef6422; }

.membership_search_page .padding-top-60 {
  padding-top: 60px !important; }

.membership_search_page .padding-60 {
  padding: 20px 0 !important; }

.membership_search_page .col-left {
  position: relative;
  min-height: 500px;
  background: #f7f7f7;
  padding-right: 0;
  padding-bottom: 10px; }
  .membership_search_page .col-left .heading {
    padding-bottom: 20px; }
    .membership_search_page .col-left .heading h4 {
      color: #515151;
      margin-bottom: 0rem; }
    .membership_search_page .col-left .heading p {
      color: #ababab;
      margin-bottom: 0rem; }
  .membership_search_page .col-left h6 {
    color: #515151;
    font-weight: bold;
    padding-left: 15px;
    line-height: 0.5px;
    margin-top: 2rem; }
  .membership_search_page .col-left .nav a {
    color: #515151; }
    .membership_search_page .col-left .nav a .fas {
      width: 25px; }
  .membership_search_page .col-left .nav a.nav-link {
    padding: .5rem 0rem;
    padding-left: 15px;
    border-top: #ccc 1px solid;
    border-radius: 0; }
  .membership_search_page .col-left .nav a:last-child {
    border-bottom: #ccc 1px solid; }
  .membership_search_page .col-left .nav a.active {
    background: #fff;
    border-left: #ccc 1px solid; }
    .membership_search_page .col-left .nav a.active span {
      position: absolute;
      width: 10px;
      right: 0;
      height: 38px;
      margin: -8px;
      background: #fff; }

.membership_search_page .col-right {
  padding: 10px 20px;
  padding-left: 30px; }
  .membership_search_page .col-right h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #ccc; }
    .membership_search_page .col-right h2 label {
      font-size: 1rem;
      float: right; }
  .membership_search_page .col-right .info_line {
    margin-bottom: 10px; }

.membership_search_page .col-left:before {
  content: "";
  width: 1000px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: #f7f7f7;
  z-index: -1; }

.membership_sidebar .heading {
  text-align: left; }

.membership_sidebar .sidebar_blocks {
  border-top: 1px solid #dfdfdf;
  padding: 20px 0; }

.membership_sidebar h5 {
  font-size: 16px;
  font-weight: bold; }

.membership_sidebar .sidebar_block {
  padding-left: 10px; }
  .membership_sidebar .sidebar_block ul {
    list-style-type: none;
    margin-left: 30px;
    padding: 0; }
  .membership_sidebar .sidebar_block li {
    margin-top: 5px; }
  .membership_sidebar .sidebar_block a span {
    color: #aaa; }

.ajaxLoader {
  display: none; }

.cssload-container {
  position: fixed;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.cssload-whirlpool,
.cssload-whirlpool::before,
.cssload-whirlpool::after {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid #cccccc;
  border-left-color: black;
  border-radius: 974px;
  -o-border-radius: 974px;
  -ms-border-radius: 974px;
  -webkit-border-radius: 974px;
  -moz-border-radius: 974px; }

.cssload-whirlpool {
  margin: -24px 0 0 -24px;
  height: 49px;
  width: 49px;
  animation: cssload-rotate 1150ms linear infinite;
  -o-animation: cssload-rotate 1150ms linear infinite;
  -ms-animation: cssload-rotate 1150ms linear infinite;
  -webkit-animation: cssload-rotate 1150ms linear infinite;
  -moz-animation: cssload-rotate 1150ms linear infinite; }

.cssload-whirlpool::before {
  content: "";
  margin: -22px 0 0 -22px;
  height: 43px;
  width: 43px;
  animation: cssload-rotate 1150ms linear infinite;
  -o-animation: cssload-rotate 1150ms linear infinite;
  -ms-animation: cssload-rotate 1150ms linear infinite;
  -webkit-animation: cssload-rotate 1150ms linear infinite;
  -moz-animation: cssload-rotate 1150ms linear infinite; }

.cssload-whirlpool::after {
  content: "";
  margin: -28px 0 0 -28px;
  height: 55px;
  width: 55px;
  animation: cssload-rotate 2300ms linear infinite;
  -o-animation: cssload-rotate 2300ms linear infinite;
  -ms-animation: cssload-rotate 2300ms linear infinite;
  -webkit-animation: cssload-rotate 2300ms linear infinite;
  -moz-animation: cssload-rotate 2300ms linear infinite; }

@keyframes cssload-rotate {
  100% {
    transform: rotate(360deg); } }

@-o-keyframes cssload-rotate {
  100% {
    -o-transform: rotate(360deg); } }

@-ms-keyframes cssload-rotate {
  100% {
    -ms-transform: rotate(360deg); } }

@-webkit-keyframes cssload-rotate {
  100% {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes cssload-rotate {
  100% {
    -moz-transform: rotate(360deg); } }

.modal-backdrop-local {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  display: none; }

#member-keyword-search {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  padding-left: 10px; }

.membership_sidebar .search_block {
  padding-left: 20px;
  padding-right: 20px; }

.sidebar-toggle-link {
  position: relative;
  cursor: pointer; }

.sidebar-toggle-link span {
  font-size: 24px;
  position: absolute;
  right: 10px;
  top: -7px; }

.collapsed .sidebar-toggle-link span {
  top: 0; }

.sidebar-toggle-link span .fa-sort-up {
  display: none; }

.collapsed .sidebar-toggle-link span .fa-sort-down {
  display: none; }

.collapsed .sidebar-toggle-link span .fa-sort-up {
  display: inline; }

.collapsed .sidebar-toggle-link span .fa-sort-down {
  display: none; }

.collapsed .sidebar_block {
  display: none; }

.national_members {
  padding: 50px 0;
  text-align: center; }

.national_members h2 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 50px;
  position: relative; }

.national_members h2 span {
  display: inline-block;
  background: #fff;
  padding: 10px;
  position: relative; }

.national_members h2 i {
  height: 1px;
  width: 100%;
  background: #aaa;
  position: absolute;
  left: 0;
  top: 32px; }

.national_members .col {
  border-right: 1px solid #aaa;
  margin-bottom: 20px;
  min-height: 120px;
  position: relative; }

.national_members .col:last-child {
  border: none; }

.national_members .col span {
  display: block;
  position: absolute;
  left: 2%;
  bottom: -10px;
  width: 96%;
  height: 1px;
  background: #aaa; }

.main_navigation {
  border-top: 1px solid #f1f1f1;
  margin-bottom: 0px; }

.main_navigation ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.main_navigation ul li {
  float: left; }

.main_navigation ul > li > a {
  color: #4f4d4c;
  display: inline-block;
  vertical-align: middle;
  padding: 0 21px;
  letter-spacing: 1.5px;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 60px;
  z-index: 1; }

.main_navigation ul > .item-101 > a {
  padding-left: 0px; }

.main_navigation ul > .item-108 > a {
  padding-right: 0px; }

.main_navigation ul > li > a:hover,
.main_navigation ul > li > a:focus,
.main_navigation ul > li.active > a {
  background: none;
  color: #3189c2; }

#navigation1 {
  background: none;
  position: absolute;
  left: 0;
  top: 20px;
  z-index: 999; }

.navigation_wrapper {
  position: relative; }

.nav-header {
  float: none;
  text-align: right; }

.nav-header .nav-toggle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 50px;
  margin-top: 0;
  margin-left: 50px;
  padding: 6px 2px 0;
  color: #70798b;
  cursor: pointer; }
  .front .nav-header .nav-toggle {
    color: #fff; }
    .front .nav-header .nav-toggle:after {
      color: #fff; }
    .front .nav-header .nav-toggle:before {
      background-color: #fff;
      box-shadow: 0 0.6em 0 0 #fff; }

.nav-header .nav-toggle1 {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  margin-top: 0;
  margin-left: 30px;
  padding: 6px 2px 0;
  color: #70798b;
  cursor: pointer; }
  .front .nav-header .nav-toggle1 {
    color: #fff; }
    .front .nav-header .nav-toggle1:after {
      color: #fff; }
    .front .nav-header .nav-toggle1:before {
      background-color: #fff;
      box-shadow: 0 0.6em 0 0 #fff; }
    .front .nav-header .nav-toggle1:before {
      background-color: #fff;
      box-shadow: 0 0.6em 0 0 #fff; }

.front .nav-header .btn-primary {
  border: 1px solid #fff;
  background: none;
  color: #fff; }
  .front .nav-header .btn-primary:active, .front .nav-header .btn-primary:focus, .front .nav-header .btn-primary:hover {
    color: #212529;
    background-color: #fff;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

#navigation1 .nav-header {
  margin-right: 40px; }

body .hc-offcanvas-nav .nav-wrapper.nav-wrapper-1 {
  overflow-x: hidden; }

.innerSideMenu {
  padding: 0px;
  margin-bottom: 50px; }
  .innerSideMenu li:first-child {
    background: #c1511b;
    padding: 14px 13px;
    font-size: 20px;
    color: #fff;
    font-weight: bold; }
  .innerSideMenu li {
    list-style: none; }
    .innerSideMenu li a {
      background: #ff8600;
      display: block;
      padding: 14px 13px;
      color: #fff;
      border-top: #ea6e32 1px solid; }
    .innerSideMenu li a:hover {
      background: #ef6422; }

.news_box {
  max-width: 320px;
  margin: 0 auto 1rem;
  overflow: hidden;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  position: relative; }

.news_box h4 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 300;
  margin: 0;
  padding-right: 20px; }

.news_box p {
  padding-right: 20px; }

.news_box .news_bottom_line {
  height: 1px;
  background: #dfdfdf;
  max-width: 50px;
  margin: 5px 0 10px 0; }

.news_box .img_area {
  width: 100%;
  height: 220px;
  background-size: cover;
  background-repeat: no-repeat; }

.news_box .btn_area {
  text-align: right; }

.news_box .btn_area .btn {
  padding: 10px 20px; }

.news_box .news_info {
  padding: 20px 0px 0 20px; }

.news_box .date_area {
  background: #f16522;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  top: 0px;
  left: 10px; }

.news_box .date_area .date {
  display: block;
  font-size: 24px;
  line-height: 28px; }

.news_box .date_area .month {
  display: block;
  font-size: 18px;
  line-height: 22px; }

.news_box .date_area .year {
  display: block;
  background: #3189c2;
  font-size: 20px;
  line-height: 26px;
  padding: 0 5px; }

.news_area {
  padding: 50px 0; }

.news_area h3 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 30px;
  text-transform: uppercase;
  text-align: center; }

.news_area .button_area {
  text-align: center; }

.news_area .button_area .btn {
  text-transform: uppercase;
  border-color: #f16522;
  margin-top: 30px; }

@media (max-width: 500px) {
  .nav-header .nav-toggle {
    top: -20px;
    margin-left: 20px; }
  .logo-wrap {
    max-width: 125px; }
  #navigation1 .nav-header {
    margin-right: 0px;
    margin-top: -10px;
    float: right; }
    #navigation1 .nav-header .btn {
      padding: 10px 20px; }
    #navigation1 .nav-header .btn-primary {
      width: 53px;
      overflow: hidden; }
      #navigation1 .nav-header .btn-primary .fa-user {
        padding-right: 23px; }
      #navigation1 .nav-header .btn-primary .fa-sign-out-alt {
        padding-right: 23px; }
  .site #navigation1 .nav-header {
    margin-right: 18px; }
  .site .inner_banner {
    padding: 160px 0; }
    .site .inner_banner h1 {
      font-size: 30px; }
  .site .innerSideMenu {
    display: none; }
  .movePageUp .main_article_area {
    padding: 30px; }
  .profileContainer .col-right {
    padding: 20px !important; }
  .login .white_box_header, .new-membership-application .white_box_header {
    padding: 0 3px; }
  .login .white_box_tabs a, .new-membership-application .white_box_tabs a {
    min-width: 150px;
    margin-right: 0px; }
  .membership_search_page .col-right {
    padding-left: 2px; }
  .front .main_banner .text_area {
    margin-top: 30vh; } }

.stroll_to_area {
  position: relative;
  display: table;
  margin: 0 auto;
  text-align: center; }
  .stroll_to_area img {
    max-width: inherit; }

.scroll_to_ebook {
  position: absolute;
  top: -20px;
  left: 0;
  z-index: 2;
  outline: none !important; }

.sendgrid_form_module .center {
  text-align: center; }

.sendgrid_form_module .font-secondary {
  font-size: 12px; }

.sendgrid_form_module input {
  text-align: left;
  border-radius: .3rem; }

.sendgrid_form_module label {
  text-align: left; }

.sendgrid_form_module .btn-block {
  display: block;
  width: 100%;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem; }

.sendgrid_form_module small {
  font-size: 10px; }

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem; }

.card > hr {
  margin-right: 0;
  margin-left: 0; }

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: .75rem; }

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-header + .list-group .list-group-item:first-child {
  border-top: 0; }

.card-footer {
  padding: .75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -.625rem;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -.625rem;
  margin-left: -.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.card-deck .card {
  margin-bottom: 15px; }

.form-label-group {
  position: relative;
  margin-bottom: 1rem; }

.form-label-group > input, .form-label-group > label {
  padding: .75rem; }

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  line-height: 1.9;
  color: #888;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out; }

.form-label-group > input.error {
  border-color: #f00; }

.form-label-group input::-webkit-input-placeholder {
  color: transparent; }

.form-label-group input:-ms-input-placeholder {
  color: transparent; }

.form-label-group input::-ms-input-placeholder {
  color: transparent; }

.form-label-group input::-moz-placeholder {
  color: transparent; }

.form-label-group input::placeholder {
  color: transparent; }

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(.75rem + .75rem * (2/3));
  padding-bottom: calc(.75rem/3); }

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(.75rem/3);
  padding-bottom: calc(.75rem/3);
  font-size: 10px;
  color: #bbb; }

*,
*:after,
*::before {
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html.menu-active,
.menu-active body,
.menu-active .st-container,
.menu-active .st-pusher,
.menu-active .st-content {
  height: 100%; }

.nav_logo {
  text-align: center; }

.menu-active .st-content {
  overflow-y: scroll;
  background: #f3efe0; }

.st-content,
.st-content-inner {
  position: relative; }

.menu-active .st-container {
  position: relative;
  overflow: hidden; }

.st-pusher {
  position: relative;
  left: 0;
  z-index: 99;
  height: 100%;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s; }

.st-pusher::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  background: rgba(0, 0, 0, 0.2);
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s; }

.st-menu-open .st-pusher::after {
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s; }

.st-menu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  visibility: hidden;
  width: 300px;
  height: 100%;
  background: #f16522;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }

.st-menu::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  content: '';
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s; }

.st-menu-open .st-menu::after {
  width: 0;
  height: 0;
  opacity: 0;
  -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s; }

/* content style */
.st-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
  overflow: scroll;
  display: block; }

.st-menu h2 {
  margin: 0;
  padding: 1em;
  color: rgba(0, 0, 0, 0.4);
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  font-weight: 300;
  font-size: 2em; }

.st-menu ul li a {
  display: block;
  padding: 1em 1em 1em 1.2em;
  outline: none;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
  color: #f3efe0;
  text-transform: uppercase;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
  letter-spacing: 1px;
  font-weight: 400;
  -webkit-transition: background 0.3s, box-shadow 0.3s;
  transition: background 0.3s, box-shadow 0.3s; }

.st-menu ul li:first-child a {
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2), inset 0 1px rgba(0, 0, 0, 0.2); }

.st-menu ul li a:hover {
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  color: #fff; }

/* Individual effects */
/* Effect 1: Slide in on top */
.st-effect-1.st-menu {
  visibility: visible;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.st-effect-1.st-menu-open .st-effect-1.st-menu {
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.st-effect-1.st-menu::after {
  display: none; }

/* Effect 2: Reveal */
.st-effect-2.st-menu-open .st-pusher {
  -webkit-transform: translate3d(300px, 0, 0);
  transform: translate3d(300px, 0, 0); }

.st-effect-2.st-menu {
  z-index: 1; }

.st-effect-2.st-menu-open .st-effect-2.st-menu {
  visibility: visible;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s; }

.st-effect-2.st-menu::after {
  display: none; }

/* Effect 3: Push*/
.st-effect-3.st-menu-open .st-pusher {
  -webkit-transform: translate3d(300px, 0, 0);
  transform: translate3d(300px, 0, 0); }

.st-effect-3.st-menu {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.st-effect-3.st-menu-open .st-effect-3.st-menu {
  visibility: visible;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s; }

.st-effect-3.st-menu::after {
  display: none; }

/* Effect 4: Slide along */
.st-effect-4.st-menu-open .st-pusher {
  -webkit-transform: translate3d(300px, 0, 0);
  transform: translate3d(300px, 0, 0); }

.st-effect-4.st-menu {
  z-index: 1;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0); }

.st-effect-4.st-menu-open .st-effect-4.st-menu {
  visibility: visible;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.st-effect-4.st-menu::after {
  display: none; }

/* Effect 5: Reverse slide out */
.st-effect-5.st-menu-open .st-pusher {
  -webkit-transform: translate3d(300px, 0, 0);
  transform: translate3d(300px, 0, 0); }

.st-effect-5.st-menu {
  z-index: 1;
  -webkit-transform: translate3d(50%, 0, 0);
  transform: translate3d(50%, 0, 0); }

.st-effect-5.st-menu-open .st-effect-5.st-menu {
  visibility: visible;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* Effect 6: Rotate pusher */
.st-effect-6.st-container {
  -webkit-perspective: 1500px;
  perspective: 1500px; }

.st-effect-6 .st-pusher {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.st-effect-6.st-menu-open .st-pusher {
  -webkit-transform: translate3d(300px, 0, 0) rotateY(-15deg);
  transform: translate3d(300px, 0, 0) rotateY(-15deg); }

.st-effect-6.st-menu {
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.st-effect-6.st-menu-open .st-effect-6.st-menu {
  visibility: visible;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: translate3d(-100%, 0, 0) rotateY(15deg);
  transform: translate3d(-100%, 0, 0) rotateY(15deg); }

.st-effect-6.st-menu::after {
  display: none; }

/* Effect 7: 3D rotate in */
.st-effect-7.st-container {
  -webkit-perspective: 1500px;
  perspective: 1500px;
  -webkit-perspective-origin: 0% 50%;
  perspective-origin: 0% 50%; }

.st-effect-7 .st-pusher {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.st-effect-7.st-menu-open .st-pusher {
  -webkit-transform: translate3d(300px, 0, 0);
  transform: translate3d(300px, 0, 0); }

.st-effect-7.st-menu {
  -webkit-transform: translate3d(-100%, 0, 0) rotateY(-90deg);
  transform: translate3d(-100%, 0, 0) rotateY(-90deg);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.st-effect-7.st-menu-open .st-effect-7.st-menu {
  visibility: visible;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: translate3d(-100%, 0, 0) rotateY(0deg);
  transform: translate3d(-100%, 0, 0) rotateY(0deg); }

/* Effect 8: 3D rotate out */
.st-effect-8.st-container {
  -webkit-perspective: 1500px;
  perspective: 1500px;
  -webkit-perspective-origin: 0% 50%;
  perspective-origin: 0% 50%; }

.st-effect-8 .st-pusher {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.st-effect-8.st-menu-open .st-pusher {
  -webkit-transform: translate3d(300px, 0, 0);
  transform: translate3d(300px, 0, 0); }

.st-effect-8.st-menu {
  -webkit-transform: translate3d(-100%, 0, 0) rotateY(90deg);
  transform: translate3d(-100%, 0, 0) rotateY(90deg);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.st-effect-8.st-menu-open .st-effect-8.st-menu {
  visibility: visible;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: translate3d(-100%, 0, 0) rotateY(0deg);
  transform: translate3d(-100%, 0, 0) rotateY(0deg); }

.st-effect-8.st-menu::after {
  display: none; }

/* Effect 9: Scale down pusher */
.st-effect-9.st-container {
  -webkit-perspective: 1500px;
  perspective: 1500px; }

.st-effect-9 .st-pusher {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.st-effect-9.st-menu-open .st-pusher {
  -webkit-transform: translate3d(0, 0, -300px);
  transform: translate3d(0, 0, -300px); }

.st-effect-9.st-menu {
  opacity: 1; }

.st-effect-9.st-menu-open .st-effect-9.st-menu {
  visibility: visible;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.st-effect-9.st-menu::after {
  display: none; }

/* Effect 10: Scale up */
.st-effect-10.st-container {
  -webkit-perspective: 1500px;
  perspective: 1500px;
  -webkit-perspective-origin: 0% 50%;
  perspective-origin: 0% 50%; }

.st-effect-10.st-menu-open .st-pusher {
  -webkit-transform: translate3d(300px, 0, 0);
  transform: translate3d(300px, 0, 0); }

.st-effect-10.st-menu {
  z-index: 1;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, -300px);
  transform: translate3d(0, 0, -300px); }

.st-effect-10.st-menu-open .st-effect-10.st-menu {
  visibility: visible;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* Effect 11: Scale and rotate pusher */
.st-effect-11.st-container {
  -webkit-perspective: 1500px;
  perspective: 1500px; }

.st-effect-11 .st-pusher {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.st-effect-11.st-menu-open .st-pusher {
  -webkit-transform: translate3d(100px, 0, -600px) rotateY(-20deg);
  transform: translate3d(100px, 0, -600px) rotateY(-20deg); }

.st-effect-11.st-menu {
  opacity: 1;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.st-effect-11.st-menu-open .st-effect-11.st-menu {
  visibility: visible;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.st-effect-11.st-menu::after {
  display: none; }

/* Effect 12: Open door */
.st-effect-12.st-container {
  -webkit-perspective: 1500px;
  perspective: 1500px; }

.st-effect-12 .st-pusher {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.st-effect-12.st-menu-open .st-pusher {
  -webkit-transform: rotateY(-10deg);
  transform: rotateY(-10deg); }

.st-effect-12.st-menu {
  opacity: 1;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.st-effect-12.st-menu-open .st-effect-12.st-menu {
  visibility: visible;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.st-effect-12.st-menu::after {
  display: none; }

/* Effect 13: Fall down */
.st-effect-13.st-container {
  -webkit-perspective: 1500px;
  perspective: 1500px;
  -webkit-perspective-origin: 0% 50%;
  perspective-origin: 0% 50%; }

.st-effect-13.st-menu-open .st-pusher {
  -webkit-transform: translate3d(300px, 0, 0);
  transform: translate3d(300px, 0, 0); }

.st-effect-13.st-menu {
  z-index: 1;
  opacity: 1;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0); }

.st-effect-13.st-menu-open .st-effect-13.st-menu {
  visibility: visible;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition-speed: 0.2s;
  transition-speed: 0.2s; }

/* Effect 14: Delayed 3D rotate */
.st-effect-14.st-container {
  -webkit-perspective: 1500px;
  perspective: 1500px;
  -webkit-perspective-origin: 0% 50%;
  perspective-origin: 0% 50%; }

.st-effect-14 .st-pusher {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.st-effect-14.st-menu-open .st-pusher {
  -webkit-transform: translate3d(300px, 0, 0);
  transform: translate3d(300px, 0, 0); }

.st-effect-14.st-menu {
  -webkit-transform: translate3d(-100%, 0, 0) rotateY(90deg);
  transform: translate3d(-100%, 0, 0) rotateY(90deg);
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.st-effect-14.st-menu-open .st-effect-14.st-menu {
  visibility: visible;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -webkit-transform: translate3d(-100%, 0, 0) rotateY(0deg);
  transform: translate3d(-100%, 0, 0) rotateY(0deg); }

/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
.no-csstransforms3d .st-pusher,
.no-js .st-pusher {
  padding-left: 300px; }

.hc-offcanvas-nav .nav-wrapper-1 .nav-close .navClose {
  margin: -3px; }

.tilingGuideBanner {
  position: relative;
  padding: 16px; }
  .tilingGuideBanner .coverImg {
    position: absolute;
    top: -75px;
    z-index: 9999; }
  .tilingGuideBanner .textContainer {
    color: #fff;
    padding: 25px 0; }
    .tilingGuideBanner .textContainer h2 {
      text-transform: uppercase; }

.tiles-and-tiling-guide .main_content, .tile-reference-manual .main_content {
  background: #f1f1f1; }

.tiles-and-tiling-guide .main_component_area, .tile-reference-manual .main_component_area {
  background: #f1f1f1; }
  .tiles-and-tiling-guide .main_component_area .max_width, .tile-reference-manual .main_component_area .max_width {
    padding: 0px; }

.tilingGuide {
  margin-top: 100px; }
  .tilingGuide .tile_box {
    max-width: 320px;
    margin: 0 auto 3rem;
    overflow: hidden;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    position: relative;
    background: #fff; }
  .tilingGuide .tile_box h4 {
    font-size: 14px;
    margin: 0 0 10px 0;
    color: #f16522;
    font-weight: bold;
    min-height: 35px; }
  .tilingGuide .tile_box p {
    padding-right: 20px; }
  .tilingGuide .tile_box .tile_bottom_line {
    height: 1px;
    background: #dfdfdf;
    max-width: 50px;
    font-weight: bold;
    margin: 5px 0 10px 0; }
  .tilingGuide .tile_box .img_area {
    width: 100%;
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat; }
  .tilingGuide .tile_box .btn_area {
    text-align: right; }
  .tilingGuide .tile_box .btn_area .btn {
    padding: 10px 20px; }
  .tilingGuide .tile_box .tile_info {
    padding: 15px 10px;
    text-align: center;
    min-height: 170px; }
  .tilingGuide .tile_box .date_area {
    background: #f16522;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 0px; }
  .tilingGuide .tile_box .date_area .date {
    display: block;
    font-size: 24px;
    line-height: 28px;
    padding: 0 20px; }
  .tilingGuide .tile_box .date_area .month {
    display: block;
    font-size: 14px;
    line-height: 15px;
    padding: 3px 15px; }
  .tilingGuide .tile_box .date_area .year {
    display: block;
    background: #3189c2;
    font-size: 20px;
    line-height: 26px;
    padding: 0 5px; }
  .tilingGuide .tile_box_heading {
    margin: 0 auto 3rem;
    overflow: hidden;
    position: relative;
    background: #fff;
    border-radius: 5px; }
  .tilingGuide .tile_box_heading h4 {
    font-size: 14px;
    margin: 0 0 10px 0;
    color: #f16522;
    font-weight: bold; }
  .tilingGuide .tile_box_heading p {
    padding-right: 20px; }
  .tilingGuide .tile_box_heading .tile_bottom_line {
    height: 1px;
    background: #dfdfdf;
    max-width: 50px;
    font-weight: bold;
    margin: 5px 0 10px 0; }
  .tilingGuide .tile_box_heading .img_area {
    width: 100%;
    height: 340px;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 15px; }
  .tilingGuide .tile_box_heading .tile_info {
    padding: 20px;
    text-align: center; }
  .tilingGuide .tile_area {
    padding: 50px 0; }
  .tilingGuide .tile_area h3 {
    font-size: 32px;
    line-height: 38px;
    font-weight: 300;
    margin: 0;
    margin-bottom: 30px;
    text-transform: uppercase;
    text-align: center; }
  .tilingGuide .tile_area .button_area {
    text-align: center; }
  .tilingGuide .tile_area .button_area .btn {
    text-transform: uppercase;
    border-color: #f16522;
    margin-top: 30px; }
  .tilingGuide .btn-login-to-download {
    border: #e3e3e3 1px solid;
    border-radius: 5px;
    padding: 0px 15px 0 0;
    margin-top: 5px;
    /* http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+0,f6f6f6+64&0+0,1+64 */
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #f6f6f6 64%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #f6f6f6 64%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #f6f6f6 64%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f6f6f6',GradientType=0 );
    /* IE6-9 */ }
    .tilingGuide .btn-login-to-download .fas {
      border-right: #e9e9e9 1px solid;
      padding: 15px;
      margin-right: 15px; }
  .tilingGuide .btn-login-to-download-2 {
    border: #e3e3e3 1px solid;
    border-radius: 5px;
    padding: 0px 15px 0 0;
    margin-top: 5px;
    color: #000 !important;
    /* http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+0,f6f6f6+64&0+0,1+64 */
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #f6f6f6 64%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #f6f6f6 64%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #f6f6f6 64%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f6f6f6',GradientType=0 );
    /* IE6-9 */
    position: relative; }
    .tilingGuide .btn-login-to-download-2 .fas {
      border-right: #e9e9e9 1px solid;
      padding: 15px;
      margin-right: 15px; }
    .tilingGuide .btn-login-to-download-2 label {
      font-size: 15px;
      position: relative;
      right: 0;
      top: -7px;
      font-weight: bold; }
    .tilingGuide .btn-login-to-download-2 span {
      font-size: 12px;
      display: block;
      position: absolute;
      right: 19px;
      bottom: 7px; }
  .tilingGuide .btn-download {
    border: 1px solid #a03a09;
    border-radius: 5px;
    padding: 0 15px 0 0;
    margin-top: 5px;
    background: #f16522;
    color: #fff; }
    .tilingGuide .btn-download .fas {
      border-right: 1px solid #ce4605;
      padding: 15px;
      margin-right: 15px; }

.popup_download_modal {
  text-align: left; }
  .popup_download_modal label {
    font-weight: bold; }

@media (max-width: 576px) {
  .tilingGuideBanner .coverImg {
    position: unset !important; } }

.form-checkbox,
.form-radio {
  position: relative;
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
  text-align: left; }

.form-checkbox-inline .form-checkbox-label,
.form-radio-inline .form-radio-label {
  display: inline-block;
  margin-right: 1rem; }

.form-checkbox-legend,
.form-radio-legend {
  margin: 0 0 0.125rem 0;
  font-weight: 500;
  font-size: 1rem;
  color: #333; }

.form-checkbox-label,
.form-radio-label {
  position: relative;
  cursor: pointer;
  padding-left: 1.5rem;
  text-align: left;
  color: #333;
  display: block;
  margin-bottom: 0.5rem; }

.form-checkbox-label:hover i,
.form-radio-label:hover i {
  color: #337ab7; }

.form-checkbox-label span,
.form-radio-label span {
  display: block; }

.form-checkbox-label input,
.form-radio-label input {
  width: auto;
  opacity: 0.0001;
  position: absolute;
  left: 0.25rem;
  top: 0.25rem;
  margin: 0;
  padding: 0; }

.form-checkbox-button {
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  color: #999;
  left: 0;
  top: 0.25rem;
  width: 1rem;
  height: 1rem;
  z-index: 0;
  border: 0.125rem solid currentColor;
  border-radius: 0.0625rem;
  transition: color 0.28s ease;
  will-change: color; }

.form-checkbox-button::before,
.form-checkbox-button::after {
  position: absolute;
  height: 0;
  width: 0.2rem;
  background-color: #337ab7;
  display: block;
  transform-origin: left top;
  border-radius: 0.25rem;
  content: "";
  transition: opacity 0.28s ease, height 0s linear 0.28s;
  opacity: 0;
  will-change: opacity, height; }

.form-checkbox-button::before {
  top: 0.65rem;
  left: 0.38rem;
  transform: rotate(-135deg);
  box-shadow: 0 0 0 0.0625rem #fff; }

.form-checkbox-button::after {
  top: 0.3rem;
  left: 0;
  transform: rotate(-45deg); }

.form-checkbox-field:checked ~ .form-checkbox-button {
  color: #337ab7; }

.form-checkbox-field:checked ~ .form-checkbox-button::after,
.form-checkbox-field:checked ~ .form-checkbox-button::before {
  opacity: 1;
  transition: height 0.28s ease; }

.form-checkbox-field:checked ~ .form-checkbox-button::after {
  height: 0.5rem; }

.form-checkbox-field:checked ~ .form-checkbox-button::before {
  height: 1.2rem;
  transition-delay: 0.28s; }

.form-radio-button {
  position: absolute;
  left: 0;
  cursor: pointer;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #999; }

.form-radio-button::before,
.form-radio-button::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: 0.25rem;
  width: 1rem;
  height: 1rem;
  transition: transform 0.28s ease, color 0.28s ease;
  border-radius: 50%;
  border: 0.125rem solid currentColor;
  will-change: transform, color; }

.form-radio-button::after {
  transform: scale(0);
  background-color: #337ab7;
  border-color: #337ab7; }

.form-radio-field:checked ~ .form-radio-button::after {
  transform: scale(0.5); }

.form-radio-field:checked ~ .form-radio-button::before {
  color: #337ab7; }

.form-has-error .form-checkbox-button,
.form-has-error .form-radio-button {
  color: #d9534f; }

.form-fieldset {
  padding: 50px 40px;
  border: 0; }

.form-fieldset + .form-fieldset {
  margin-top: 15px; }

.form-legend {
  padding: 1em 0 0;
  margin: 0 0 -0.5em;
  font-size: 1.5rem;
  text-align: center; }

.form-legend + p {
  margin-top: 1rem; }

.form-element {
  position: relative;
  margin-top: 2.25rem;
  margin-bottom: 2.25rem; }

.form-element-hint {
  font-weight: 400;
  font-size: 0.6875rem;
  color: #a6a6a6;
  display: block; }

.form-element-bar {
  position: relative;
  height: 1px;
  background: #999;
  display: block; }

/*.form-element-bar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #337ab7;
  height: 2px;
  display: block;
  transform: rotateY(180deg);
  transition: transform 0.28s ease;
  will-change: transform;
}*/
.form-element-label {
  position: absolute;
  top: 0.75rem;
  line-height: 1.5rem;
  pointer-events: none;
  padding-left: 0.125rem;
  z-index: 1;
  font-size: .9rem;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  color: #a6a6a6;
  transform: translateY(-50%);
  transform-origin: left center;
  transition: transform 0.28s ease, color 0.28s linear, opacity 0.28s linear;
  will-change: transform, color, opacity; }

.form-element-field {
  outline: none;
  height: 1.5rem;
  display: block;
  background: none;
  padding: 0.125rem 0.125rem 0.0625rem;
  font-size: 1rem;
  border: 0 solid transparent;
  line-height: 1.5;
  width: 100%;
  color: #333;
  box-shadow: none;
  opacity: 0.001;
  transition: opacity 0.28s ease;
  will-change: opacity; }

.form-element-field:-ms-input-placeholder {
  color: #a6a6a6;
  transform: scale(0.9);
  transform-origin: left top; }

.form-element-field::placeholder {
  color: #a6a6a6;
  transform: scale(0.9);
  transform-origin: left top; }

.form-element-field:focus ~ .form-element-bar::after {
  transform: rotateY(0deg); }

.form-element-field:focus ~ .form-element-label {
  color: #337ab7; }

.form-element-field.-hasvalue,
.form-element-field:focus {
  opacity: 1; }

.form-element-field.-hasvalue ~ .form-element-label,
.form-element-field:focus ~ .form-element-label {
  transform: translateY(-100%) translateY(-0.5em) translateY(-2px) scale(0.9);
  cursor: pointer;
  pointer-events: auto; }

.form-has-error .form-element-label.form-element-label,
.form-has-error .form-element-hint {
  color: #d9534f; }

.form-has-error .form-element-bar,
.form-has-error .form-element-bar::after {
  background: #d9534f; }

.form-is-success .form-element-label.form-element-label,
.form-is-success .form-element-hint {
  color: #259337; }

.form-is-success .form-element-bar::after {
  background: #259337; }

input.form-element-field:not(:placeholder-shown),
textarea.form-element-field:not(:placeholder-shown) {
  opacity: 1; }

input.form-element-field:not(:placeholder-shown) ~ .form-element-label,
textarea.form-element-field:not(:placeholder-shown) ~ .form-element-label {
  transform: translateY(-100%) translateY(-0.5em) translateY(-2px) scale(0.9);
  cursor: pointer;
  pointer-events: auto; }

textarea.form-element-field {
  height: auto;
  min-height: 3rem; }

select.form-element-field {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer; }

.form-select-placeholder {
  color: #a6a6a6;
  display: none; }

.form-select select:focus ~ .form-element-bar::before {
  transform: translateY(-50%) rotateX(180deg) rotate(45deg); }

.form-element-field[type="number"] {
  -moz-appearance: textfield; }

.form-select .arrow {
  height: 1.5em;
  width: 0.5em;
  float: right;
  margin-right: 0.5em;
  margin-top: -24px; }
  .form-select .arrow .fas {
    color: #ccc; }

.form-element-field[type="number"]::-webkit-outer-spin-button,
.form-element-field[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.reset-row-form-top {
  margin-top: -2.25rem; }

.reset-row-form-bottom {
  margin-bottom: -2.25rem; }

.white_box_main_area {
  background: #fff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.white_box_main {
  position: relative;
  top: -120px; }

.white_box_header {
  padding: 0 40px; }

.white_box_tabs a {
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
  line-height: 50px;
  background: rgba(255, 255, 255, 0.9);
  padding: 0 20px;
  min-width: 170px;
  color: #000;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.white_box_tabs a.active, .white_box_tabs a:hover {
  background: #fff; }

.white_box_tabs a.disabled {
  cursor: default;
  background: rgba(255, 255, 255, 0.9); }

.registration_page .white_box_tabs a:last-child:hover {
  background: rgba(255, 255, 255, 0.9); }

.white_box_main_area h2 {
  text-transform: uppercase;
  font-size: 24px;
  line-height: 28px;
  color: #ccc; }

.white_box_main_area h2 label {
  font-size: 1rem;
  font-weight: normal;
  float: right;
  color: #222;
  text-transform: capitalize;
  display: inline-block; }

.white_box_main_area .info_area {
  margin-top: 2.25rem; }

.white_box_main_area .info_line {
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 5px;
  display: table; }
  .white_box_main_area .info_line .title {
    color: #ccc;
    display: inline-block;
    min-width: 160px; }

.white_box_main_area .border_bottom {
  padding: 0 0 10px 0;
  border-bottom: 1.5px solid #ccc;
  margin-bottom: 10px; }

.white_box_main_area .due_data, .white_box_main_area .due_data .title {
  color: #f16522; }

.white_box_main_area .note {
  font-size: .8rem; }

.white_box_main_area .note a {
  color: #f16522; }

.white_box_main_area .note_data {
  font-size: 13px;
  margin-top: 20px;
  color: #ccc; }

.white_box_main_area .agree_data {
  margin-top: 30px;
  font-size: 13px; }

.white_box_main_area .button_area {
  padding: 30px 0; }

.white_box_main_area .form-btn {
  display: inline-block;
  padding: 15px 30px;
  text-transform: uppercase;
  background: #f16522;
  color: #fff; }

.white_box_main_area .tab-container {
  display: none; }

.white_box_main_area .tab-container.active {
  display: block; }

.white_box_main_area .payment_method .pay_title {
  font-size: 14px;
  color: #ccc;
  margin-bottom: 5px; }

.white_box_main_area .payment_method .payment-method-link {
  display: inline-block;
  margin-right: 10px;
  background: #ddd;
  color: #333;
  padding-right: 30px;
  height: 35px;
  line-height: 35px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  overflow: hidden;
  border: 1px solid #ccc;
  font-size: 14px; }

.white_box_main_area .payment_method .payment-method-link span {
  display: inline-block;
  background: #ccc;
  width: 30px;
  height: 35px;
  line-height: 35px;
  vertical-align: middle;
  margin-right: 20px;
  margin-top: -4px;
  text-align: center; }

.white_box_main_area .payment_method .payment-method-link span svg {
  display: none; }

.white_box_main_area .payment_method .payment-method-link.active span, .white_box_main_area .payment_method .payment-method-link:hover span {
  background: #259337;
  color: #fff; }

.white_box_main_area .payment_method .payment-method-link.active span svg, .white_box_main_area .payment_method .payment-method-link:hover span svg {
  display: inline; }

.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease; }

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df; }

.StripeElement--invalid {
  border-color: #fa755a; }

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important; }

.payment-method-con {
  display: none; }

.payment-method-con.active {
  display: block; }

.payment-method-con {
  padding: 10px 0; }

.main-buttons {
  display: none !important; }

.main-buttons.active {
  display: inline-block !important; }

.login_page .login_page_mask {
  padding: 3px;
  border: 1px solid #dfdfdf;
  max-width: 320px;
  margin: 0 auto; }

.login_page .login_page_mask_wrapper {
  max-width: 320px;
  padding: 30px;
  border: 1px solid #cfcfcf; }

.login_page .button_area {
  padding: 0; }

.login_page .button_area .form-btn {
  width: 100%;
  display: block; }

.login_page .login_page_info {
  padding-bottom: 40px; }
  .login_page .login_page_info .login_page_mask_wrapper {
    padding: 20px; }
  .login_page .login_page_info ul {
    list-style-type: none;
    margin: 0; }
  .login_page .login_page_info ul li a {
    color: #333; }

.postal_address_head {
  margin-top: 40px; }

.bd-example-tabs .nav-link .fa-exclamation-triangle {
  float: right; }

.bd-example-tabs .fa-exclamation-triangle {
  color: #E26D38; }

.bd-example-tabs .complete .fa-exclamation-triangle {
  display: none; }

.form-element .fa-exclamation-triangle {
  color: #E26D38;
  z-index: 999;
  position: absolute;
  top: 3px;
  left: -20px; }

.form-element .saved-icons {
  color: #259337;
  z-index: 999;
  position: absolute;
  top: 3px;
  right: 0px;
  display: none; }

.form-element .error-icons {
  color: #d9534f;
  z-index: 999;
  position: absolute;
  top: 3px;
  right: 0px;
  display: none; }

.form-element .ajaxSpinner {
  color: #333;
  z-index: 999;
  position: absolute;
  top: 3px;
  right: 0px;
  display: none; }

.form-element.form-optional .fa-exclamation-triangle {
  display: none; }

.form-element.-childhasvalue .fa-exclamation-triangle {
  display: none; }

.form-element.-childhasvalue.form-saved .saved-icons {
  display: inline-block; }

.form-element.-childhasvalue.form-error .error-icons {
  display: inline-block; }

.form-element.-childhasvalue.form-error .saved-icons {
  display: none; }

.form-element.-childhasvalue.form-processing .saved-icons {
  display: none; }

.form-element.-childhasvalue.form-processing .ajaxSpinner {
  display: inline-block; }

.form-element.form-readonly {
  position: relative; }

.form-element.form-readonly::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.1); }

.location-table {
  width: 100%;
  border-collapse: collapse; }
  .location-table th {
    background: #dfdfdf;
    color: #333;
    padding: 5px;
    width: 110px;
    text-align: left; }
  .location-table td {
    width: 110px;
    padding: 5px;
    overflow-wrap: anywhere; }

.location_section .location_title {
  width: 50%;
  float: left; }

.location_section .location_action {
  width: 50%;
  float: left;
  text-align: right; }

.location_section .fa-exclamation-triangle {
  color: #E26D38; }

.single_location {
  padding: 20px 0;
  border-bottom: 1px solid #dfdfdf; }

.single_location.active .location_section {
  display: none; }

.make-it-popup {
  display: none; }

.make-it-popup.active {
  display: block; }

.make-it-popup .overlay-popup {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;
  padding: 0 1rem;
  opacity: 0;
  transform: scale(0);
  transition: transform 0s, opacity 0.3s; }

.make-it-popup.active .overlay-popup {
  opacity: 1;
  transform: scale(1); }

.make-it-popup .modal_container {
  max-width: 500px;
  width: 90%;
  padding: 1rem;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  opacity: 0;
  transform-origin: center center;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s, opacity 0.3s; }

.make-it-popup.active .modal_container {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1); }

.make-it-popup label {
  text-align: left;
  display: block; }

.popup_buttons {
  text-align: right; }

.make-it-popup input {
  box-sizing: border-box;
  width: 100%;
  height: 40px; }

.make-it-popup select {
  box-sizing: border-box;
  width: 100%;
  height: 40px; }

.account_contact_wrapper, .address_contact_wrapper {
  display: none; }

.banner_area {
  position: relative; }
  .banner_area #system-message-container {
    position: absolute;
    z-index: 999;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    margin: auto;
    display: table;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); }
    .banner_area #system-message-container #system-message {
      padding: 10px; }

.profileContainer .padding-top-60 {
  padding-top: 60px !important; }

.profileContainer .padding-60 {
  padding: 60px 0 !important; }

.profileContainer .col-left {
  position: relative;
  min-height: 500px;
  background: #f7f7f7;
  padding-right: 0;
  padding-bottom: 10px; }
  .profileContainer .col-left .heading {
    padding: 10px;
    text-align: center; }
    .profileContainer .col-left .heading h4 {
      color: #515151;
      margin-bottom: 0rem; }
    .profileContainer .col-left .heading p {
      color: #ababab;
      margin-bottom: 0rem; }
  .profileContainer .col-left h6 {
    color: #515151;
    font-weight: bold;
    padding-left: 15px;
    line-height: 0.5px;
    margin-top: 2rem; }
  .profileContainer .col-left .nav a {
    color: #515151; }
    .profileContainer .col-left .nav a .fas {
      width: 25px; }
  .profileContainer .col-left .nav a.nav-link {
    padding: .5rem 0rem;
    padding-left: 15px;
    border-top: #ccc 1px solid;
    border-radius: 0; }
  .profileContainer .col-left .nav a:last-child {
    border-bottom: #ccc 1px solid; }
  .profileContainer .col-left .nav a.active {
    background: #fff;
    border-left: #ccc 1px solid; }
    .profileContainer .col-left .nav a.active span {
      position: absolute;
      width: 10px;
      right: 0;
      height: 38px;
      margin: -8px;
      background: #fff; }
  .profileContainer .col-left .menu li {
    width: 100%;
    padding-left: 7px; }
    .profileContainer .col-left .menu li a {
      padding: 7px 0 7px 7px;
      display: block; }
  .profileContainer .col-left .menu li:first-child a {
    border-top: #ccc 1px solid; }

.profileContainer .col-right {
  padding: 0px 20px;
  padding: 70px 0 70px 70px; }
  .profileContainer .col-right h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #ccc; }
    .profileContainer .col-right h2 label {
      font-size: 1rem;
      float: right; }
  .profileContainer .col-right .info_line {
    margin-bottom: 10px; }

.profileContainer .col-left:before {
  content: "";
  width: 1000px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: #f7f7f7;
  z-index: -1; }

.profileContainer .col-right h2 {
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  background: #aaa;
  padding: 5px 15px;
  margin-bottom: 25px; }

.login_page_wrapper .row {
  margin: 0px;
  padding: 4% 0 4% 0; }

.login_page_wrapper .col-left {
  padding: 20px 20px 20px 40px; }
  .login_page_wrapper .col-left h4 {
    color: #aaa;
    text-transform: uppercase;
    font-weight: bold; }
  .login_page_wrapper .col-left .button_area {
    float: right;
    width: 170px; }
    .login_page_wrapper .col-left .button_area button {
      border: 0; }

.login_page_wrapper .col-right {
  padding: 20px 20px 20px 40px; }
  .login_page_wrapper .col-right h4 {
    color: #aaa;
    text-transform: uppercase;
    font-weight: bold; }
  .login_page_wrapper .col-right h5 {
    color: #000;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold; }

.forgotpwd_page_wrapper .row {
  margin: 0px;
  padding: 4% 0 4% 0; }

.forgotpwd_page_wrapper .col-left {
  padding: 20px 0px 20px 0px; }
  .forgotpwd_page_wrapper .col-left h4 {
    color: #aaa;
    text-transform: uppercase;
    font-weight: bold; }
  .forgotpwd_page_wrapper .col-left label {
    color: #ccc; }
  .forgotpwd_page_wrapper .col-left input {
    width: 100%; }
  .forgotpwd_page_wrapper .col-left .button_area {
    float: right;
    width: 170px; }
    .forgotpwd_page_wrapper .col-left .button_area button {
      border: 0; }

.forgotpwd_page_wrapper #user-forgotpassword .controls {
  text-align: center; }

.forgotpwd_page_wrapper #user-forgotpassword button {
  margin: auto;
  border: 0;
  margin-top: 15px; }

.resetPasswordFrm #verifyCode {
  display: none; }

.resetPasswordFrm #updatePassword {
  display: none; }

.resetPasswordFrm .alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  display: none; }

.resetPasswordFrm .alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }

.resetPasswordFrm .alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }

.resetPasswordFrm .control-label {
  margin-top: 10px; }

.resetPasswordFrm input {
  width: 100%;
  border: #ccc 1px solid;
  padding: 5px; }

.resetPasswordFrm .btnContainer {
  text-align: center;
  margin-top: 10px; }
  .resetPasswordFrm .btnContainer .form-btn {
    color: #fff;
    cursor: pointer; }
  .resetPasswordFrm .btnContainer .form-btn:hover {
    color: #fff; }

.profileContainer .joomla_sidebar_module .menu li a {
  padding: .5rem 0;
  padding-left: 15px;
  border-top: 1px solid #ccc;
  border-bottom: none;
  border-radius: 0; }

.profileContainer .joomla_sidebar_module .menu li a:last-child {
  border-bottom: none; }

.profileContainer .joomla_sidebar_module .menu li:first-child a {
  border-bottom: none; }

.profileContainer .joomla_sidebar_module .menu li:last-child a {
  border-bottom: none; }

.profileContainer .col-left .joomla_sidebar_module .nav a.active span {
  position: static;
  margin: 0px; }

#membershipTabs .database-search-button {
  display: block;
  color: #fff;
  background: #e6720f;
  text-align: center;
  padding: 10px 0px; }

.change_password_buttons {
  text-align: right;
  padding: 20px 0; }
  .change_password_buttons .form-btn {
    display: inline-block;
    padding: 15px 30px;
    text-transform: uppercase;
    background: #f16522;
    color: #fff;
    display: inline-block;
    margin-top: 0;
    height: 35px;
    line-height: 35px;
    padding: 0 20px; }

.single_location .edit_contact {
  width: 50px;
  display: inline-block; }

.single_location .delete_contact {
  width: 70px;
  display: inline-block;
  margin-top: 3px;
  color: #880000; }

.popup_buttons .btn {
  padding: 10px 20px; }

.popup_buttons .btn-secondary {
  background: #999; }

.popup_buttons .btn-secondary:hover, .popup_buttons .btn-secondary:focus {
  background: #888;
  border-color: #888; }

.location_details input {
  border-radius: 0px;
  border-left: none;
  border-right: none;
  border-top: none; }

.location_details select {
  border-radius: 0px; }

.profileContainer .col-left .heading {
  text-align: left;
  margin-bottom: 30px;
  font-size: 12px; }

.profileContainer .col-left .heading_wrapper {
  border: 1px solid #dfdfdf;
  padding: 20px;
  background: #fff; }

.profileContainer .col-left .heading .status {
  text-align: right;
  padding-right: 10px;
  padding-bottom: 5px;
  margin-bottom: 25px; }

.profileContainer .col-left .heading p {
  color: #ababab;
  margin-bottom: 5px;
  font-size: 12px; }

.profileContainer .col-left .heading p strong {
  min-width: 110px;
  display: inline-block; }

.renew_sidebar .heading {
  text-align: left;
  margin-bottom: 30px;
  font-size: 12px; }

.renew_sidebar .heading p {
  color: #ababab;
  margin-bottom: 5px; }

.renew_sidebar .heading p strong {
  min-width: 110px;
  display: inline-block; }

.profile-right.white__box__with_border {
  padding: 60px; }

.profile-right.white__box__with_border h1 {
  margin-bottom: 30px; }

.research_interests_fields {
  padding: 30px 45px; }

.how_did_you_learn {
  padding: 30px 40px; }

.special_skills_wrap {
  padding: 30px 60px; }

.heading .logout {
  margin-bottom: 30px;
  display: inline-block;
  background: none;
  box-shadow: none;
  border: none !important;
  text-shadow: none;
  color: #d40c01;
  padding: 0;
  font-weight: normal; }

.registration_logout.heading {
  position: relative; }

.registration_logout.heading .logout {
  margin-bottom: 0;
  position: absolute;
  right: 0px;
  top: 0px; }

.heading .logout:hover {
  color: #ec817b; }

.renew__button {
  padding: 10px 20px 10px 10px; }

.renew-buttons {
  display: inline-block;
  margin-top: 0;
  height: 35px;
  line-height: 35px;
  padding: 0;
  text-shadow: none;
  border: none !important;
  border-radius: 1px;
  background: #f08533;
  color: #fff;
  font-weight: 700;
  box-shadow: none;
  width: 100%;
  text-align: center; }

.renew-buttons:hover, .renew-buttons:focus {
  color: #fff; }

.video_area {
  padding: 50px 0;
  background: url(../images/video-bg.jpg) no-repeat;
  background-size: cover; }

.video_area h3 {
  font-size: 32px;
  line-height: 38px;
  margin-top: 30px;
  font-weight: 300;
  margin: 0;
  text-transform: uppercase; }

.video_area p {
  line-height: 22px; }

.video_area .text_area_box {
  background: #fff;
  padding: 40px;
  position: relative; }

@media (min-width: 768px) {
  .video_area .text_area_box:after {
    position: absolute;
    top: 20px;
    left: -20px;
    width: 34px;
    height: 58px;
    background: url(../images/left-arrow.png) no-repeat;
    content: ''; } }

.bold {
  font-weight: bold; }

.modal_overlay {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  padding: 0 1rem;
  opacity: 0;
  transform: scale(0);
  transition: transform 0s, opacity 0.3s; }
  .modal_overlay.is-active {
    opacity: 1;
    transform: scale(1); }

.modal_container {
  max-width: 500px;
  width: 90%;
  padding: 1rem;
  background: #fff;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  opacity: 0;
  transform-origin: center center;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s, opacity 0.3s; }
  .modal_container.is-active {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1); }

.modal_heading {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center; }
  .modal_heading svg {
    width: 130px;
    height: 3rem; }

.modal_body {
  text-align: left; }

.modal_copy {
  padding-top: 0.5rem;
  font-size: 1rem;
  color: black;
  text-align: center;
  text-transform: uppercase; }

.modal_form {
  margin-top: 2rem; }
  .modal_form.is-processing {
    opacity: 0.5; }

.modal-form-input {
  width: 100%;
  padding: 1.25rem 1rem;
  border-width: 2px;
  color: #242323;
  font-size: 1rem;
  text-align: center;
  box-shadow: none; }

.modal_close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  transform: scale(0.8);
  transition: transform 0.2s ease;
  border: none;
  background: transparent;
  cursor: pointer;
  opacity: 1;
  z-index: 1; }
  .modal_close:active, .modal_close:focus, .modal_close:hover {
    transform: scale(1); }
  .modal_close:before, .modal_close:after {
    position: absolute;
    top: 0;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 3px;
    background-color: black; }
  .modal_close:before {
    transform: rotate(45deg); }
  .modal_close:after {
    transform: rotate(-45deg); }

.modal-feedback {
  margin-top: 1rem; }

@media (min-width: 768px) {
  .modal_container {
    width: 100%;
    padding: 2rem; }
  .modal_close {
    top: 2rem;
    right: 2rem; }
  .modal_heading {
    font-size: 2.25rem; }
  .modal_copy {
    font-size: 0.8rem; }
  .modal-form-input {
    padding: 1.75rem 1rem;
    font-size: 1.5rem; } }
