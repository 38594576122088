.tilingGuideBanner{
    position: relative;
    padding:16px;
    .coverImg{
        position:absolute;
        top:-75px;
        z-index:9999;
    }

    .textContainer{
        color:#fff;
        padding:25px 0;
        h2{ text-transform: uppercase;}
    }

}



.tiles-and-tiling-guide, .tile-reference-manual{
    .main_content{
        background: #f1f1f1;
    }
    .main_component_area {
        background: #f1f1f1;
         .max_width{ padding:0px; }
         
    }
   
}


.tilingGuide{
        margin-top:100px;
        
        .tile_box {
            max-width: 320px;
            margin: 0 auto 3rem;
            overflow: hidden;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.500);
            position: relative;
            background: #fff;
        
        }
        
        .tile_box h4 {
            font-size: 14px;
            //line-height: 38px;
            margin:0 0 10px 0;
            color: #f16522;
            font-weight: bold;
            min-height: 35px;

        }
        
        .tile_box p {
            padding-right: 20px;
        }
        
        .tile_box .tile_bottom_line {
            height: 1px;
            background: #dfdfdf;
            max-width: 50px;
            font-weight: bold;
            margin: 5px 0 10px 0;

        }
        
        .tile_box .img_area {
            width: 100%;
            height: 150px;
            background-size: cover;
            background-repeat: no-repeat;
        }
        
        .tile_box .btn_area {
            text-align: right;
        }
        
        .tile_box .btn_area .btn {
            padding: 10px 20px;
        }
        
        .tile_box .tile_info {
            padding: 15px 10px;
            text-align: center;
            min-height: 170px;
        }
        
        .tile_box .date_area {
            background: #f16522;
            color: #fff;
            text-align: center;
            //text-transform: uppercase;
            position: absolute;
            top: 0px;
            //left: 10px;
        }
        
        .tile_box .date_area .date {
            display: block;
            font-size: 24px;
            line-height: 28px;
            padding:0 20px;
        }
        
        .tile_box .date_area .month {
            display: block;
            font-size: 14px;
            line-height: 15px;
            padding: 3px 15px;
        }
        
        .tile_box .date_area .year {
            display: block;
            background: $c-blue;
            font-size: 20px;
            line-height: 26px;
            padding: 0 5px;
        }

        //--------heading Box-----------------
        .tile_box_heading {
            //max-width: 320px;
            margin: 0 auto 3rem;
            overflow: hidden;
            //box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.500);
            position: relative;
            background: #fff;
            border-radius: 5px;
        
        }
        
        .tile_box_heading h4 {
            font-size: 14px;
            margin:0 0 10px 0;
            color: #f16522;
            font-weight: bold;

        }
        
        .tile_box_heading p {
            padding-right: 20px;
        }
        
        .tile_box_heading .tile_bottom_line {
            height: 1px;
            background: #dfdfdf;
            max-width: 50px;
            font-weight: bold;
            margin: 5px 0 10px 0;

        }
        
      
        .tile_box_heading .img_area {
            width: 100%;
            height: 340px;
            background-size: cover;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin-top: 15px;
        }

        .tile_box_heading .tile_info {
            padding: 20px;
            text-align: center;
        }
        //------------------------------------
        
        
        .tile_area {
            padding: 50px 0;
        }
        
        .tile_area h3 {
            font-size: 32px;
            line-height: 38px;
            font-weight: 300;
            margin: 0;
            margin-bottom: 30px;
            text-transform: uppercase;
            text-align: center;
        }
        
        .tile_area .button_area {
            text-align: center;
        }
        
        .tile_area .button_area .btn {
            text-transform: uppercase;
            border-color: #f16522;
            margin-top: 30px;
        }

        .btn-login-to-download{
            border:#e3e3e3 1px solid;
            border-radius: 5px;
            padding: 0px 15px 0 0;
            margin-top:5px;

           /* http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+0,f6f6f6+64&0+0,1+64 */
            background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(246,246,246,1) 64%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(246,246,246,1) 64%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(246,246,246,1) 64%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f6f6f6',GradientType=0 ); /* IE6-9 */

            .fas {
                border-right: #e9e9e9 1px solid;
                padding: 15px;
                margin-right: 15px;
            }
        }

        .btn-login-to-download-2{
            border:#e3e3e3 1px solid;
            border-radius: 5px;
            padding: 0px 15px 0 0;
            margin-top:5px;
            color:#000 !important;

           /* http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+0,f6f6f6+64&0+0,1+64 */
            background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(246,246,246,1) 64%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(246,246,246,1) 64%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(246,246,246,1) 64%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f6f6f6',GradientType=0 ); /* IE6-9 */

            .fas {
                border-right: #e9e9e9 1px solid;
                padding: 15px;
                margin-right: 15px;
            }
            position: relative;
            label{ 
                font-size: 15px;
                position: relative;
                right: 0;
                top: -7px;
                font-weight: bold;
            }
            span{ 
                font-size: 12px;
                display: block;
                position: absolute;
                right: 19px;
                bottom: 7px;
            }
        }

        
        .btn-download{
            border: 1px solid #a03a09;
            border-radius: 5px;
            padding: 0 15px 0 0;
            margin-top: 5px;
            background: #f16522;
            color: #fff;

            .fas {
                border-right: 1px solid #ce4605;
                padding: 15px;
                margin-right: 15px;
            }
        }
  
}

.popup_download_modal{
    text-align: left;

    label{
        font-weight: bold;
    }
    
}

@media (max-width: 576px){
    .tilingGuideBanner{
        .coverImg{ position:unset !important; }
    }

}