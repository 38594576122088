*,
*:after,
*::before {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

html.menu-active,
.menu-active body,
.menu-active .st-container,
.menu-active .st-pusher,
.menu-active .st-content {
	height: 100%;
}

.nav_logo{
  text-align: center;
}
.menu-active .st-content {
	overflow-y: scroll;
	background: #f3efe0;
}

.st-content,
.st-content-inner {
	position: relative;
}

.menu-active .st-container {
	position: relative;
	overflow: hidden;
}

.st-pusher {
	position: relative;
	left: 0;
	z-index: 99;
	height: 100%;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
}

.st-pusher::after {
	position: absolute;
	top: 0;
	right: 0;
	width: 0;
	height: 0;
	background: rgba(0,0,0,0.2);
	content: '';
	opacity: 0;
	-webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
	transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
}

.st-menu-open .st-pusher::after {
	width: 100%;
	height: 100%;
	opacity: 1;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.st-menu {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 100;
	visibility: hidden;
	width: 300px;
	height: 100%;
	background: #f16522;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
}

.st-menu::after {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.2);
	content: '';
	opacity: 1;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
}

.st-menu-open .st-menu::after {
	width: 0;
	height: 0;
	opacity: 0;
	-webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
	transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
}

/* content style */

.st-menu ul {
	margin: 0;
	padding: 0;
	list-style: none;

	height: 100%;
	overflow: scroll;
	display: block;
}

.st-menu h2 {
	margin: 0;
	padding: 1em;
	color: rgba(0,0,0,0.4);
	text-shadow: 0 0 1px rgba(0,0,0,0.1);
	font-weight: 300;
	font-size: 2em;
}

.st-menu ul li a {
	display: block;
	padding: 1em 1em 1em 1.2em;
	outline: none;
	box-shadow: inset 0 -1px rgba(0,0,0,0.2);
	color: #f3efe0;
	text-transform: uppercase;
	text-shadow: 0 0 1px rgba(255,255,255,0.1);
	letter-spacing: 1px;
	font-weight: 400;
	-webkit-transition: background 0.3s, box-shadow 0.3s;
	transition: background 0.3s, box-shadow 0.3s;
}

.st-menu ul li:first-child a {
	box-shadow: inset 0 -1px rgba(0,0,0,0.2), inset 0 1px rgba(0,0,0,0.2);
}

.st-menu ul li a:hover {
	background: rgba(0,0,0,0.2);
	box-shadow: inset 0 -1px rgba(0,0,0,0);
	color: #fff;
}

/* Individual effects */

/* Effect 1: Slide in on top */
.st-effect-1.st-menu {
	visibility: visible;
	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
}

.st-effect-1.st-menu-open .st-effect-1.st-menu {
	visibility: visible;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.st-effect-1.st-menu::after {
	display: none;
}

/* Effect 2: Reveal */
.st-effect-2.st-menu-open .st-pusher {
	-webkit-transform: translate3d(300px, 0, 0);
	transform: translate3d(300px, 0, 0);
}

.st-effect-2.st-menu {
	z-index: 1;
}

.st-effect-2.st-menu-open .st-effect-2.st-menu {
	visibility: visible;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
}

.st-effect-2.st-menu::after {
	display: none;
}

/* Effect 3: Push*/
.st-effect-3.st-menu-open .st-pusher {
	-webkit-transform: translate3d(300px, 0, 0);
	transform: translate3d(300px, 0, 0);
}

.st-effect-3.st-menu {
	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
}

.st-effect-3.st-menu-open .st-effect-3.st-menu {
	visibility: visible;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
}

.st-effect-3.st-menu::after {
	display: none;
}

/* Effect 4: Slide along */
.st-effect-4.st-menu-open .st-pusher {
	-webkit-transform: translate3d(300px, 0, 0);
	transform: translate3d(300px, 0, 0);
}

.st-effect-4.st-menu {
	z-index: 1;
	-webkit-transform: translate3d(-50%, 0, 0);
	transform: translate3d(-50%, 0, 0);
}

.st-effect-4.st-menu-open .st-effect-4.st-menu {
	visibility: visible;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.st-effect-4.st-menu::after {
	display: none;
}

/* Effect 5: Reverse slide out */
.st-effect-5.st-menu-open .st-pusher {
	-webkit-transform: translate3d(300px, 0, 0);
	transform: translate3d(300px, 0, 0);
}

.st-effect-5.st-menu {
	z-index: 1;
	-webkit-transform: translate3d(50%, 0, 0);
	transform: translate3d(50%, 0, 0);
}

.st-effect-5.st-menu-open .st-effect-5.st-menu {
	visibility: visible;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

/* Effect 6: Rotate pusher */

.st-effect-6.st-container {
	-webkit-perspective: 1500px;
	perspective: 1500px;
}

.st-effect-6 .st-pusher {
	-webkit-transform-origin: 0% 50%;
	transform-origin: 0% 50%;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.st-effect-6.st-menu-open .st-pusher {
	-webkit-transform: translate3d(300px, 0, 0) rotateY(-15deg);
	transform: translate3d(300px, 0, 0) rotateY(-15deg);
}

.st-effect-6.st-menu {
	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.st-effect-6.st-menu-open .st-effect-6.st-menu {
	visibility: visible;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	-webkit-transform: translate3d(-100%, 0, 0) rotateY(15deg);
	transform: translate3d(-100%, 0, 0) rotateY(15deg);
}

.st-effect-6.st-menu::after {
	display: none;
}

/* Effect 7: 3D rotate in */

.st-effect-7.st-container {
	-webkit-perspective: 1500px;
	perspective: 1500px;
	-webkit-perspective-origin: 0% 50%;
	perspective-origin: 0% 50%;
}

.st-effect-7 .st-pusher {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.st-effect-7.st-menu-open .st-pusher {
	-webkit-transform: translate3d(300px, 0, 0);
	transform: translate3d(300px, 0, 0);
}

.st-effect-7.st-menu {
	-webkit-transform: translate3d(-100%, 0, 0) rotateY(-90deg);
	transform: translate3d(-100%, 0, 0) rotateY(-90deg);
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.st-effect-7.st-menu-open .st-effect-7.st-menu {
	visibility: visible;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	-webkit-transform: translate3d(-100%, 0, 0) rotateY(0deg);
	transform: translate3d(-100%, 0, 0) rotateY(0deg);
}

/* Effect 8: 3D rotate out */

.st-effect-8.st-container {
	-webkit-perspective: 1500px;
	perspective: 1500px;
	-webkit-perspective-origin: 0% 50%;
	perspective-origin: 0% 50%;
}

.st-effect-8 .st-pusher {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.st-effect-8.st-menu-open .st-pusher {
	-webkit-transform: translate3d(300px, 0, 0);
	transform: translate3d(300px, 0, 0);
}

.st-effect-8.st-menu {
	-webkit-transform: translate3d(-100%, 0, 0) rotateY(90deg);
	transform: translate3d(-100%, 0, 0) rotateY(90deg);
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.st-effect-8.st-menu-open .st-effect-8.st-menu {
	visibility: visible;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	-webkit-transform: translate3d(-100%, 0, 0) rotateY(0deg);
	transform: translate3d(-100%, 0, 0) rotateY(0deg);
}

.st-effect-8.st-menu::after {
	display: none;
}

/* Effect 9: Scale down pusher */

.st-effect-9.st-container {
	-webkit-perspective: 1500px;
	perspective: 1500px;
}

.st-effect-9 .st-pusher {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.st-effect-9.st-menu-open .st-pusher {
	-webkit-transform: translate3d(0, 0, -300px);
	transform: translate3d(0, 0, -300px);
}

.st-effect-9.st-menu {
	opacity: 1;
	// -webkit-transform: translate3d(100%, 0, 0);
	// transform: translate3d(100%, 0, 0);
}

.st-effect-9.st-menu-open .st-effect-9.st-menu {
	visibility: visible;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.st-effect-9.st-menu::after {
	display: none;
}

/* Effect 10: Scale up */

.st-effect-10.st-container {
	-webkit-perspective: 1500px;
	perspective: 1500px;
	-webkit-perspective-origin: 0% 50%;
	perspective-origin: 0% 50%;
}

.st-effect-10.st-menu-open .st-pusher {
	-webkit-transform: translate3d(300px, 0, 0);
	transform: translate3d(300px, 0, 0);
}

.st-effect-10.st-menu {
	z-index: 1;
	opacity: 1;
	-webkit-transform: translate3d(0, 0, -300px);
	transform: translate3d(0, 0, -300px);
}

.st-effect-10.st-menu-open .st-effect-10.st-menu {
	visibility: visible;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

/* Effect 11: Scale and rotate pusher */

.st-effect-11.st-container {
	-webkit-perspective: 1500px;
	perspective: 1500px;
}

.st-effect-11 .st-pusher {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.st-effect-11.st-menu-open .st-pusher {
	-webkit-transform: translate3d(100px, 0, -600px) rotateY(-20deg);
	transform: translate3d(100px, 0, -600px) rotateY(-20deg);
}

.st-effect-11.st-menu {
	opacity: 1;
	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
}

.st-effect-11.st-menu-open .st-effect-11.st-menu {
	visibility: visible;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.st-effect-11.st-menu::after {
	display: none;
}

/* Effect 12: Open door */

.st-effect-12.st-container {
	-webkit-perspective: 1500px;
	perspective: 1500px;
}

.st-effect-12 .st-pusher {
	-webkit-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.st-effect-12.st-menu-open .st-pusher {
	-webkit-transform: rotateY(-10deg);
	transform: rotateY(-10deg);
}

.st-effect-12.st-menu {
	opacity: 1;
	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
}

.st-effect-12.st-menu-open .st-effect-12.st-menu {
	visibility: visible;
	-webkit-transition: -webkit-transform 0.5s;
	transition: transform 0.5s;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.st-effect-12.st-menu::after {
	display: none;
}

/* Effect 13: Fall down */

.st-effect-13.st-container {
	-webkit-perspective: 1500px;
	perspective: 1500px;
	-webkit-perspective-origin: 0% 50%;
	perspective-origin: 0% 50%;
}

.st-effect-13.st-menu-open .st-pusher {
	-webkit-transform: translate3d(300px, 0, 0);
	transform: translate3d(300px, 0, 0);
}

.st-effect-13.st-menu {
	z-index: 1;
	opacity: 1;
	-webkit-transform: translate3d(0, -100%, 0);
	transform: translate3d(0, -100%, 0);
}

.st-effect-13.st-menu-open .st-effect-13.st-menu {
	visibility: visible;
	-webkit-transition-timing-function: ease-in-out;
	transition-timing-function: ease-in-out;
	-webkit-transition-property: -webkit-transform;
	transition-property: transform;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-transition-speed: 0.2s;
	transition-speed: 0.2s;
}

/* Effect 14: Delayed 3D rotate */

.st-effect-14.st-container {
	-webkit-perspective: 1500px;
	perspective: 1500px;
	-webkit-perspective-origin: 0% 50%;
	perspective-origin: 0% 50%;
}

.st-effect-14 .st-pusher {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.st-effect-14.st-menu-open .st-pusher {
	-webkit-transform: translate3d(300px, 0, 0);
	transform: translate3d(300px, 0, 0);
}

.st-effect-14.st-menu {
	-webkit-transform: translate3d(-100%, 0, 0) rotateY(90deg);
	transform: translate3d(-100%, 0, 0) rotateY(90deg);
	-webkit-transform-origin: 0% 50%;
	transform-origin: 0% 50%;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.st-effect-14.st-menu-open .st-effect-14.st-menu {
	visibility: visible;
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
	-webkit-transition-timing-function: ease-in-out;
	transition-timing-function: ease-in-out;
	-webkit-transition-property: -webkit-transform;
	transition-property: transform;
	-webkit-transform: translate3d(-100%, 0, 0) rotateY(0deg);
	transform: translate3d(-100%, 0, 0) rotateY(0deg);
}

/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
.no-csstransforms3d .st-pusher,
.no-js .st-pusher {
	padding-left: 300px;
}
.hc-offcanvas-nav .nav-wrapper-1 .nav-close .navClose{
	margin: -3px;
}