
.contact-us .page-header h2 {
  text-align: center;
  font-size: 42px;
  line-height: 48px;
  text-transform: uppercase;
  margin-bottom: 50px;
  position: relative;
}

.contact-us .page-header h2 span {
  display: inline-block;
  background: #eff3f5;
  padding: 10px;
  position: relative;
}

.contact-us .page-header h2 i {
  height: 1px;
  width: 100%;
  background: #aaa;
  position: absolute;
  left: 0;
  top: 32px;
}

.contact_area {
  padding: 50px 0;
  background: url('../images/contact-banner-gray.jpg') no-repeat;
  background-size: cover;
}

.contact_area_bg {
  background: #fff;
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.28);
}

.contact_form_wrapper {

  input, select {
    border-radius: 0;
    border-top: none;
    border-right: none;
  }

  .form-control {
    border: 1px solid #ced4da !important;
    padding: .375rem .75rem !important;
  }
 .input-group-text { width:40px; }

 .row > div{
   margin-bottom:16px;
 }
  
  h2 {
  text-align: center;
  text-transform: uppercase;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 30px;
}
}
