$modal-is-active: '&.is-active';

.modal_overlay {
  background: rgba( black, 0.6 );
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  padding: 0 1rem;

  opacity: 0;
  transform: scale(0);

  transition: transform 0s, opacity 0.3s;

  #{$modal-is-active} {
    opacity: 1;
    transform: scale(1);
  }

}

.modal_container {
  max-width: 500px;
  width: 90%;
  padding: 1rem;
  background: $c-white;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  opacity: 0;
  transform-origin: center center;
  transform: translate(-50%, -50%) scale(0);

  transition: transform 0.3s, opacity 0.3s;

  #{$modal-is-active} {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

}

.modal_heading {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;

  svg {
    width: 130px;
    height: 3rem;
  }
}

.modal_body {
  text-align: left;
}

.modal_copy {
  padding-top: 0.5rem;
  font-size: 1rem;
  color: black;
  text-align: center;
  text-transform: uppercase;
}

.modal_form {
  margin-top: 2rem;

  &.is-processing {
    opacity: 0.5;
  }
}

.modal-form-input {
  width: 100%;
  padding: 1.25rem 1rem;
  border-width: 2px;
  color: $c-black;
  font-size: 1rem;
  text-align: center;
  box-shadow: none;
}

// close button

.modal_close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  transform: scale(0.8);
  transition: transform 0.2s ease;
  border: none;
  background: transparent;
  cursor: pointer;
  opacity: 1;
  z-index: 1;

  @include hover {
    transform: scale(1);
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 3px;
    background-color: black;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.modal-feedback {
  margin-top: 1rem;
}

@include media('>=md') {

  .modal_container {
    width: 100%;
    padding: 2rem;
  }

  .modal_close {
    top: 2rem;
    right: 2rem;
  }

  .modal_heading {
    font-size: 2.25rem;
  }

  .modal_copy {
    font-size: 0.8rem;
  }

  .modal-form-input {
    padding: 1.75rem 1rem;
    font-size: 1.5rem;
  }
}
