.main_article_area {

  padding: 2rem 0 4rem;

  h3 {
    margin-top: 3rem;
  }

  li {
    margin-bottom: 0.25rem;
  }

}
.sidebar_area{
  padding: 2rem 0 4rem;
}
