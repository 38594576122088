
/*** Banner area ***/
$scroll-factor: 0.5;
.main_banner {
  // background: url(../images/main-banner.jpg) no-repeat center center;
  // background-size: cover;
  color: #fff;
  height: calc(100vh - 490px);
  min-height: 450px;
}

.front .main_banner {
  height: calc(100vh - 40px);
  min-height: 520px;
}

.main_banner_wrapper {
  position: relative;
  z-index: 1;
  pointer-events: none;
  max-width: 82%;
  margin: 0 auto;
}

.main_banner .text_area {
  margin-top: 20%;
}

.front .main_banner .text_area {
  margin-top: 55vh;
}

.main_banner .text_area h5 {
  text-transform: uppercase;
  font-size: 30px;
  line-height: 34px;
  margin-bottom: 0px;
  font-weight: 600;
}

.main_banner .text_area h3 {
  text-transform: uppercase;
  font-size: 64px;
  line-height: 70px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 30px;
}

.main_banner .text_area h4 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0px;
  font-weight: 600;
  margin-bottom: 10px;
}

.main_banner .text_area .btn_area {
  margin-top: 20px;
}

.main_banner .text_area .btn_area .btn {
  text-transform: uppercase;
  color: #fff;
  margin-right: 10px;
  font-size: 14px;
  line-height: 24px;
  pointer-events: initial;
}

.main_banner .text_area .btn_area .btn-warning {
  // box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.75);
}

.main_banner .text_area .btn_area .btn-light {
  border-color: #fff;
}

.rest_of_body{
  position: relative;
  z-index: 1;
  background: #fff;
}