.sendgrid_form_module{
	.center{
		text-align: center;
	}
	.font-secondary{
		font-size: 12px;
	}
	input{
		text-align: left;
		border-radius: .3rem;
	}
	label{
		text-align: left;
	}
	.btn-block{
		display: block;
		width: 100%;
		padding: .5rem 1rem;
		font-size: 1.25rem;
		line-height: 1.5;
		border-radius: .3rem;
	}
	small{
		font-size: 10px;
	}
}
.card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0,0,0,.125);
	border-radius: .25rem
}
.card>hr {
	margin-right: 0;
	margin-left: 0
}
.card>.list-group:first-child .list-group-item:first-child {
	border-top-left-radius: .25rem;
	border-top-right-radius: .25rem
}
.card>.list-group:last-child .list-group-item:last-child {
	border-bottom-right-radius: .25rem;
	border-bottom-left-radius: .25rem
}
.card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1.25rem
}
.card-title {
	margin-bottom: .75rem
}
.card-subtitle {
	margin-top: -.375rem;
	margin-bottom: 0
}
.card-text:last-child {
	margin-bottom: 0
}
.card-link:hover {
	text-decoration: none
}
.card-link+.card-link {
	margin-left: 1.25rem
}
.card-header {
	padding: .75rem 1.25rem;
	margin-bottom: 0;
	background-color: rgba(0,0,0,.03);
	border-bottom: 1px solid rgba(0,0,0,.125)
}
.card-header:first-child {
	border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0
}
.card-header+.list-group .list-group-item:first-child {
	border-top: 0
}
.card-footer {
	padding: .75rem 1.25rem;
	background-color: rgba(0,0,0,.03);
	border-top: 1px solid rgba(0,0,0,.125)
}
.card-footer:last-child {
	border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px)
}
.card-header-tabs {
	margin-right: -.625rem;
	margin-bottom: -.75rem;
	margin-left: -.625rem;
	border-bottom: 0
}
.card-header-pills {
	margin-right: -.625rem;
	margin-left: -.625rem
}
.card-img-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1.25rem
}
.card-img {
	width: 100%;
	border-radius: calc(0.25rem - 1px)
}
.card-img-top {
	width: 100%;
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px)
}
.card-img-bottom {
	width: 100%;
	border-bottom-right-radius: calc(0.25rem - 1px);
	border-bottom-left-radius: calc(0.25rem - 1px)
}
.card-deck {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column
}
.card-deck .card {
	margin-bottom: 15px
}

.form-label-group {
	position: relative;
	margin-bottom: 1rem
}
.form-label-group>input, .form-label-group>label {
	padding: .75rem
}
.form-label-group>label {
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	margin-bottom: 0;
	line-height: 1.9;
	color: #888;
	border: 1px solid transparent;
	border-radius: .25rem;
	transition: all .1s ease-in-out
}
.form-label-group>input.error{
	border-color:#f00;
}
.form-label-group input::-webkit-input-placeholder {
color:transparent
}
.form-label-group input:-ms-input-placeholder {
color:transparent
}
.form-label-group input::-ms-input-placeholder {
color:transparent
}
.form-label-group input::-moz-placeholder {
color:transparent
}
.form-label-group input::placeholder {
color:transparent
}
.form-label-group input:not(:placeholder-shown) {
padding-top:calc(.75rem + .75rem * (2/3));
	padding-bottom: calc(.75rem/3)
}
.form-label-group input:not(:placeholder-shown)~label {
	padding-top: calc(.75rem/3);
	padding-bottom: calc(.75rem/3);
	font-size: 10px;
	color: #bbb
}