.inner_banner {
  background: url(../images/contact-main-banner.jpg) no-repeat center center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 150px;
  text-align: center;
  height: 364px;
}

.inner_banner h1 {
  font-size: 42px;
  line-height: 48px;
  font-weight: 600;
}

.main_content {
  position: relative;
  z-index: 1;
  background-color: $c-white;
}

.main_component_area {
  position: relative;
  padding: 50px 0;
  background: #fff;
  color: #242323;
  z-index: 1;
}

.contact-us .main_component_area {
  background: #eff3f5;
}


.page-header h2 {
  text-transform: uppercase;
}


.image_holder {
  margin-top: 30px;
}

.image_holder img {
  width: 100%;
}

//------Main Article Area------------------
.main_article_area{
  background: #fff;  
  padding:100px;
  .indentedChecklist {
    padding-left: 30px;
    h6 {position: relative}
    h6 .far {
      position: absolute;
      left: -30px;
      top: 4px;
    }
  }

  p.emphasised {
    color: #999;
    display: block;
    padding-left: 15px;
    border-left: 1px solid #444;
    font-style: italic;
  }
}

.movePageUp{
  .main_component_area{  background: #ededed; padding:0px; }
  .main_component_area_wrapper{
    position:relative;
    top:-200px;
    h1{ color:#3189c2; }
    h2{ color:#000; text-transform: uppercase; }
    h4{ font-size: 1.2rem !important }
    h6{ font-size: 1.2rem !important; color:#f16522; line-height: 1.6rem; }

    .sidebar_area { padding: 0px; }

  }
  .inner_banner_wrapper{ display:none;}
  
}
//-------------------------------------

