
.ausmap_area {
  padding: 50px 0;
}

.ausmap_area h2 {
  font-size: 32px;
  line-height: 38px;
  margin-top: 30px;
  font-weight: 300;
}

.down_line {
  height: 1px;
  background: #dfdfdf;
  max-width: 150px;
  margin: 15px 0;
}

.ausmap_area ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.ausmap_area ul li {
  padding-left: 30px;
  background: url(../images/arrow-bullet.png) no-repeat;
  background-position: center left;
  margin-bottom: 10px;
  font-size: 14px;
}

.ausmap_area .btn {
  color: #fff;
  line-height: 40px;
  text-transform: uppercase;
}

.ausmap_area .button_area {
  margin-top: 40px;
}
