.form-checkbox,
.form-radio {
  position: relative;
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
  text-align: left;
}

.form-checkbox-inline .form-checkbox-label,
.form-radio-inline .form-radio-label {
  display: inline-block;
  margin-right: 1rem;
}

.form-checkbox-legend,
.form-radio-legend {
  margin: 0 0 0.125rem 0;
  font-weight: 500;
  font-size: 1rem;
  color: #333;
}

.form-checkbox-label,
.form-radio-label {
  position: relative;
  cursor: pointer;
  padding-left: 1.5rem;
  text-align: left;
  color: #333;
  display: block;
  margin-bottom: 0.5rem;
}

.form-checkbox-label:hover i,
.form-radio-label:hover i {
  color: #337ab7;
}

.form-checkbox-label span,
.form-radio-label span {
  display: block;
}

.form-checkbox-label input,
.form-radio-label input {
  width: auto;
  opacity: 0.0001;
  position: absolute;
  left: 0.25rem;
  top: 0.25rem;
  margin: 0;
  padding: 0;
}

.form-checkbox-button {
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  color: #999;
  left: 0;
  top: 0.25rem;
  width: 1rem;
  height: 1rem;
  z-index: 0;
  border: 0.125rem solid currentColor;
  border-radius: 0.0625rem;
  transition: color 0.28s ease;
  will-change: color;
}

.form-checkbox-button::before,
.form-checkbox-button::after {
  position: absolute;
  height: 0;
  width: 0.2rem;
  background-color: #337ab7;
  display: block;
  transform-origin: left top;
  border-radius: 0.25rem;
  content: "";
  transition: opacity 0.28s ease, height 0s linear 0.28s;
  opacity: 0;
  will-change: opacity, height;
}

.form-checkbox-button::before {
  top: 0.65rem;
  left: 0.38rem;
  transform: rotate(-135deg);
  box-shadow: 0 0 0 0.0625rem #fff;
}

.form-checkbox-button::after {
  top: 0.3rem;
  left: 0;
  transform: rotate(-45deg);
}

.form-checkbox-field:checked ~ .form-checkbox-button {
  color: #337ab7;
}

.form-checkbox-field:checked ~ .form-checkbox-button::after,
.form-checkbox-field:checked ~ .form-checkbox-button::before {
  opacity: 1;
  transition: height 0.28s ease;
}

.form-checkbox-field:checked ~ .form-checkbox-button::after {
  height: 0.5rem;
}

.form-checkbox-field:checked ~ .form-checkbox-button::before {
  height: 1.2rem;
  transition-delay: 0.28s;
}

.form-radio-button {
  position: absolute;
  left: 0;
  cursor: pointer;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #999;
}

.form-radio-button::before,
.form-radio-button::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: 0.25rem;
  width: 1rem;
  height: 1rem;
  transition: transform 0.28s ease, color 0.28s ease;
  border-radius: 50%;
  border: 0.125rem solid currentColor;
  will-change: transform, color;
}

.form-radio-button::after {
  transform: scale(0);
  background-color: #337ab7;
  border-color: #337ab7;
}

.form-radio-field:checked ~ .form-radio-button::after {
  transform: scale(0.5);
}

.form-radio-field:checked ~ .form-radio-button::before {
  color: #337ab7;
}

.form-has-error .form-checkbox-button,
.form-has-error .form-radio-button {
  color: #d9534f;
}

.form-fieldset {
  padding: 50px 40px;
  border: 0;
}

.form-fieldset + .form-fieldset {
  margin-top: 15px;
}

.form-legend {
  padding: 1em 0 0;
  margin: 0 0 -0.5em;
  font-size: 1.5rem;
  text-align: center;
}

.form-legend + p {
  margin-top: 1rem;
}

.form-element {
  position: relative;
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.form-element-hint {
  font-weight: 400;
  font-size: 0.6875rem;
  color: #a6a6a6;
  display: block;
}

.form-element-bar {
  position: relative;
  height: 1px;
  background: #999;
  display: block;
}

/*.form-element-bar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #337ab7;
  height: 2px;
  display: block;
  transform: rotateY(180deg);
  transition: transform 0.28s ease;
  will-change: transform;
}*/

.form-element-label {
  position: absolute;
  top: 0.75rem;
  line-height: 1.5rem;
  pointer-events: none;
  padding-left: 0.125rem;
  z-index: 1;
  font-size: .9rem;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  color: #a6a6a6;
  transform: translateY(-50%);
  transform-origin: left center;
  transition: transform 0.28s ease, color 0.28s linear, opacity 0.28s linear;
  will-change: transform, color, opacity;
}

.form-element-field {
  outline: none;
  height: 1.5rem;
  display: block;
  background: none;
  padding: 0.125rem 0.125rem 0.0625rem;
  font-size: 1rem;
  border: 0 solid transparent;
  line-height: 1.5;
  width: 100%;
  color: #333;
  box-shadow: none;
  opacity: 0.001;
  transition: opacity 0.28s ease;
  will-change: opacity;
}

.form-element-field:-ms-input-placeholder {
  color: #a6a6a6;
  transform: scale(0.9);
  transform-origin: left top;
}

.form-element-field::placeholder {
  color: #a6a6a6;
  transform: scale(0.9);
  transform-origin: left top;
}

.form-element-field:focus ~ .form-element-bar::after {
  transform: rotateY(0deg);
}

.form-element-field:focus ~ .form-element-label {
  color: #337ab7;
}

.form-element-field.-hasvalue,
.form-element-field:focus {
  opacity: 1;
}

.form-element-field.-hasvalue ~ .form-element-label,
.form-element-field:focus ~ .form-element-label {
  transform: translateY(-100%) translateY(-0.5em) translateY(-2px) scale(0.9);
  cursor: pointer;
  pointer-events: auto;
}

.form-has-error .form-element-label.form-element-label,
.form-has-error .form-element-hint {
  color: #d9534f;
}

.form-has-error .form-element-bar,
.form-has-error .form-element-bar::after {
  background: #d9534f;
}

.form-is-success .form-element-label.form-element-label,
.form-is-success .form-element-hint {
  color: #259337;
}

.form-is-success .form-element-bar::after {
  background: #259337;
}

input.form-element-field:not(:placeholder-shown),
textarea.form-element-field:not(:placeholder-shown) {
  opacity: 1;
}

input.form-element-field:not(:placeholder-shown) ~ .form-element-label,
textarea.form-element-field:not(:placeholder-shown) ~ .form-element-label {
  transform: translateY(-100%) translateY(-0.5em) translateY(-2px) scale(0.9);
  cursor: pointer;
  pointer-events: auto;
}

textarea.form-element-field {
  height: auto;
  min-height: 3rem;
}

select.form-element-field {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.form-select-placeholder {
  color: #a6a6a6;
  display: none;
}

// .form-select .form-element-bar::before {
//   content: "";
//   position: absolute;
//   height: 0.5em;
//   width: 0.5em;
//   border-bottom: 1px solid #999;
//   border-right: 1px solid #999;
//   display: block;
//   right: 0.5em;
//   bottom: 0;
//   transition: transform 0.28s ease;
//   transform: translateY(-100%) rotateX(0deg) rotate(45deg);
//   will-change: transform;
//   z-index:0;
// }

.form-select select:focus ~ .form-element-bar::before {
  transform: translateY(-50%) rotateX(180deg) rotate(45deg);
}

.form-element-field[type="number"] {
  -moz-appearance: textfield;
}

//-------------
.form-select .arrow{
    height: 1.5em;
    width: 0.5em;
    float: right;
    margin-right: 0.5em;
    margin-top: -24px;
    .fas{ color:#ccc;}
}
//-------------

.form-element-field[type="number"]::-webkit-outer-spin-button,
.form-element-field[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.reset-row-form-top{
  margin-top:-2.25rem;
}
.reset-row-form-bottom{
  margin-bottom:-2.25rem;
}

.white_box_main_area{
  background: #fff;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.white_box_main{
  position: relative;
  top: -120px;
}
.white_box_header{
  padding: 0 40px;
}

.white_box_tabs a{
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
  line-height: 50px;
  background:rgba(255,255,255,0.9);
  padding: 0 20px;
  min-width: 170px;
  color: #000;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.white_box_tabs a.active,.white_box_tabs a:hover{
  background: #fff;
}
.white_box_tabs a.disabled{
  cursor: default;
  background:rgba(255,255,255,0.9);
}
//---------------------
.registration_page{
  .white_box_tabs a:last-child:hover{
    background:rgba(255,255,255,0.9);
  }
}
//---------------------

.white_box_main_area{
  h2{
    text-transform: uppercase;
    font-size: 24px;
    line-height: 28px;
    color: #ccc;
  }
  h2 label{
    font-size: 1rem;
    font-weight: normal;
    float: right;
    color: #222;
    text-transform: capitalize;
    display: inline-block;
  }
  .info_area{
    margin-top: 2.25rem;
  }
  .info_line{
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 5px;
    display: table;

    .title{
      color: #ccc;
      display: inline-block;
      min-width: 160px;
    }
  }
  .border_bottom{
    padding: 0 0 10px 0;
    border-bottom: 1.5px solid #ccc;
    margin-bottom: 10px;
  }
  .due_data,.due_data .title{
    color:#f16522;
  }
  .note{
    font-size: .8rem;
  }
  .note a{
    color:#f16522;
  }
  .note_data{
    font-size: 13px;
    margin-top: 20px;
    color:#ccc;
  }
  .agree_data{
    margin-top: 30px;
    font-size: 13px;
  }

  .button_area{
    padding: 30px 0;
  }
  .form-btn{
    display: inline-block;
    padding: 15px 30px;
    text-transform: uppercase;
    background: #f16522;
    color:#fff;
  }

  .tab-container{
    display:none;
  }
  .tab-container.active{
    display:block;
  }

  .payment_method{
    .pay_title{
      font-size: 14px;
      color:#ccc;
      margin-bottom: 5px;
    }
    .payment-method-link{
      display: inline-block;
      margin-right: 10px;
      background: #ddd;
      color:#333;
      padding-right:30px;
      height: 35px;
      line-height: 35px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      overflow: hidden;
      border: 1px solid #ccc;
      font-size: 14px;
    }
    .payment-method-link span{
      display: inline-block;
      background: #ccc;
      width: 30px;
      height: 35px;
      line-height: 35px;
      vertical-align: middle;
      margin-right: 20px;
      margin-top: -4px;
      text-align: center;
    }
    .payment-method-link span svg{
      display: none;
    }
    .payment-method-link.active span,.payment-method-link:hover span{
      background: #259337;
      color: #fff;
    }
    .payment-method-link.active span svg,.payment-method-link:hover span svg{
      display: inline;
      
    }
  }
}


.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.payment-method-con{
  display:none;
}
.payment-method-con.active{
  display: block;
}
.payment-method-con{
  padding: 10px 0;
}
.main-buttons{
  display: none!important;
}
.main-buttons.active{
  display: inline-block!important;
}

.login_page{
  .login_page_mask{
    padding: 3px;
    border: 1px solid #dfdfdf;
    max-width: 320px;
    margin: 0 auto;
  }
  .login_page_mask_wrapper{
    max-width: 320px;
    padding: 30px;
    border: 1px solid #cfcfcf;
  }

  .button_area{
    padding: 0;
  }
  .button_area .form-btn{
    width:100%;
    display: block;
  }
  .login_page_info{
    padding-bottom: 40px;

    .login_page_mask_wrapper{
      padding: 20px;
    }
    ul{
      list-style-type: none;
      margin: 0;
    }
    ul li a{
      color:#333;
    }

  }
  
}

.postal_address_head{
  margin-top: 40px;
}

.bd-example-tabs{
  .nav-link .fa-exclamation-triangle{
   float: right;
  }
  .fa-exclamation-triangle{
    color:#E26D38;
  }
  .complete .fa-exclamation-triangle{
    display: none;
  }
}

.form-element{
  .fa-exclamation-triangle{
    color:#E26D38;
    z-index: 999;
    position: absolute;
    top: 3px;
    left:-20px;
  }
  .saved-icons{
    color: #259337;
    z-index: 999;
    position: absolute;
    top: 3px;
    right:0px;
    display: none;
  }
  .error-icons{
    color: #d9534f;
    z-index: 999;
    position: absolute;
    top: 3px;
    right:0px;
    display: none;
  }
  .ajaxSpinner{
    color: #333;
    z-index: 999;
    position: absolute;
    top: 3px;
    right:0px;
    display: none;
  }
}
.form-element.form-optional{
  .fa-exclamation-triangle{
    display: none;
  }
}
.form-element.-childhasvalue{
  .fa-exclamation-triangle{
    display: none;
  }
}
.form-element.-childhasvalue.form-saved{
  .saved-icons{
    display: inline-block;
  }
}
.form-element.-childhasvalue.form-error{
  .error-icons{
    display: inline-block;
  }
  .saved-icons{
    display: none;
  }
}
.form-element.-childhasvalue.form-processing{
  .saved-icons{
    display: none;
  }
  .ajaxSpinner{
    display: inline-block;
  }
}
.form-element.form-readonly{
  position: relative;
}
.form-element.form-readonly::after{
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(255,255,255,0.1);
}

.location-table{
  width: 100%;
  border-collapse: collapse;

  th{
    background: #dfdfdf;
    color: #333;
    padding: 5px;
    width: 110px;
    text-align: left;
  }
  td{
    width: 110px;
    padding: 5px;
    overflow-wrap: anywhere;
  }
}


.location_section{
  .location_title{
    width: 50%;
    float: left;
  }
  .location_action{
    width: 50%;
    float: left;
    text-align: right;
  }
  .fa-exclamation-triangle{
    color:#E26D38;
  }
}
.single_location{
  padding: 20px 0;
  border-bottom:1px solid #dfdfdf;
}
.single_location.active .location_section{
  display: none;
}

.make-it-popup{
	display:none;
}
.make-it-popup.active{
	display:block;
}

.make-it-popup .overlay-popup {
  background: rgba( 0,0,0, 0.6 );
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;
  padding: 0 1rem;
  opacity: 0;
  transform: scale(0);
  transition: transform 0s, opacity 0.3s;

}

.make-it-popup.active .overlay-popup{
	opacity: 1;
    transform: scale(1);
}

.make-it-popup .modal_container {
  max-width: 500px;
  width: 90%;
  padding: 1rem;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  opacity: 0;
  transform-origin: center center;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s, opacity 0.3s;

  //height: 95%;overflow: scroll;

}
.make-it-popup.active .modal_container {
	opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}
.make-it-popup label{
  text-align: left;
  display: block;
}
.popup_buttons{
	text-align:right;
}
.make-it-popup{
  input{
    box-sizing: border-box;
    width: 100%;
    height: 40px;
  }
  select{
    box-sizing: border-box;
    width: 100%;
    height: 40px;
  }
}

.account_contact_wrapper,.address_contact_wrapper{
  display: none;
}

//------Error Message-------------
.banner_area{ position: relative;

  #system-message-container{
    position: absolute;
    z-index: 999;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    margin: auto;
    display: table;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

    #system-message{
      padding:10px;
    }
  }
}
//--------------------
.profileContainer{
    .padding-top-60{
      padding-top: 60px!important;
    }
    .padding-60{
      padding: 60px 0!important;
    }
    .col-left{
      position: relative;
      min-height: 500px;
       background:#f7f7f7;
       padding-right:0;
       padding-bottom:10px;
      .heading{
        padding:10px;
        text-align: center;
        h4{ color:#515151; margin-bottom: 0rem;}
        p{color:#ababab; margin-bottom: 0rem;}
      }
      h6{ color:#515151; font-weight: bold; padding-left:15px; line-height: 0.5px; margin-top:2rem;}
      .nav{
        a{ color:#515151;
          .fas{ width:25px;}
        }
        a.nav-link {
            padding: .5rem 0rem; padding-left: 15px;
            border-top: #ccc 1px solid;
            border-radius:0;
        }
        a:last-child{ border-bottom:#ccc 1px solid; }
        a.active{
          background: #fff;  border-left:#ccc 1px solid;
          span{
            position: absolute;
            width: 10px;
            right: 0;
            height: 38px;
            margin: -8px;
            background: #fff;
          }
        }
      }
      .menu{
          
           li{
            width: 100%;padding-left: 7px;
            a{  
              padding:7px 0 7px 7px;
              display: block;
              //border-top:#ccc 1px solid;
            }
          }
          li:first-child a{  
            border-top:#ccc 1px solid;
          }
      }
    }
    .col-right{
       padding: 0px 20px;
       padding: 70px 0 70px 70px;
       h2{ font-size: 1.5rem; text-transform:uppercase; color:#ccc;
        
         label{
           font-size: 1rem; float: right;
         }
      }
       

       .info_line { margin-bottom: 10px; }
    
    }
}

.profileContainer .col-left:before{
  content: "";
  width: 1000px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: #f7f7f7;
  z-index: -1;
}

.profileContainer .col-right h2 {
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  background: #aaa;
  padding: 5px 15px;
  margin-bottom: 25px;
}


//--------------
.login_page_wrapper{
    .row{ margin:0px; padding:4% 0 4% 0; }
    .col-left{
      padding:20px 20px 20px 40px;   
      h4{ color:#aaa; text-transform:uppercase; font-weight: bold;}
      .button_area{
        float:right; width:170px;
        button{ border:0;}
      }
    }

    .col-right{
       padding:20px 20px 20px 40px;  
      h4{ color:#aaa; text-transform:uppercase; font-weight: bold;}
      h5{ color:#000; font-size: 1rem; text-transform:uppercase; font-weight: bold;}
    }

}
//--------------
.forgotpwd_page_wrapper{
  .row{ margin:0px; padding:4% 0 4% 0; }
  .col-left{

    padding:20px 0px 20px 0px;   
    h4{ color:#aaa; text-transform:uppercase; font-weight: bold;}
    label{ color:#ccc;}
    input{ width:100%;}
    .button_area{
      float:right; width:170px;
      button{ border:0;}
    }
  }

  #user-forgotpassword{
    .controls{ text-align:center;}
    button {
        margin:auto;
        border: 0;
        margin-top: 15px;
    }
  }

}


//--------------------


.resetPasswordFrm{

  #verifyCode { display:none;}
  #updatePassword{ display:none;}

  .alert {
      position: relative;
      padding: .75rem 1.25rem;
      margin-bottom: 1rem;
      border: 1px solid transparent;
      border-radius: .25rem;
      display: none;
  }
  .alert-danger{color: #721c24;background-color: #f8d7da; border-color: #f5c6cb; }
  .alert-success { color: #155724; background-color: #d4edda; border-color: #c3e6cb; }

  .control-label{ margin-top:10px;}

  input{ width:100%; border:#ccc 1px solid; padding:5px;}
  .btnContainer{
        text-align: center; margin-top:10px;
      .form-btn{
          color:#fff; cursor:pointer;
      }
      .form-btn:hover{  color:#fff; }
  }
  
 
}


//-------------------

.profileContainer .joomla_sidebar_module .menu li a{
  padding: .5rem 0;
  padding-left: 15px;
  border-top: 1px solid #ccc;
  border-bottom:none;
  border-radius: 0;
}
.profileContainer .joomla_sidebar_module .menu li a:last-child{
  border-bottom:none;
}
.profileContainer .joomla_sidebar_module .menu li:first-child a{
    border-bottom:none;
}
.profileContainer .joomla_sidebar_module .menu li:last-child a{
    border-bottom:none;
}
.profileContainer .col-left .joomla_sidebar_module .nav a.active span{
  position:static;
  margin:0px;
}

#membershipTabs .database-search-button{
  display: block;
  color: #fff;
  background: #e6720f;
  text-align: center;
  padding: 10px 0px;
}

.change_password_buttons {
  text-align: right;
  padding: 20px 0;
  .form-btn{
    display: inline-block;
    padding: 15px 30px;
    text-transform: uppercase;
    background: #f16522;
    color:#fff;
    display: inline-block;
    margin-top: 0;
    height: 35px;
    line-height: 35px;
    padding: 0 20px;
  }
}

.single_location{
  .edit_contact{
    width: 50px;
    display: inline-block;
  }
  .delete_contact{
    width: 70px;
    display: inline-block;
    margin-top: 3px;
    color: #880000;
  }
}

.popup_buttons{
  .btn{
    padding: 10px 20px;
  }
    .btn-secondary{
      background: #999;
    }
    .btn-secondary:hover,.btn-secondary:focus{
      background: #888;
      border-color: #888;
    }
} 
.location_details{
  input{
    border-radius: 0px;
    border-left: none;
    border-right: none;
    border-top:none;
  }
  select{
    border-radius: 0px;
  }
}

.profileContainer .col-left .heading {
  text-align: left;
  margin-bottom: 30px;
  font-size: 12px;
}
.profileContainer .col-left .heading_wrapper {
  border: 1px solid #dfdfdf;
  padding: 20px;
  background: #fff;
}
.profileContainer .col-left .heading .status{
  text-align: right;
  padding-right: 10px;
  padding-bottom: 5px;
  margin-bottom: 25px;
}
.profileContainer .col-left .heading p {
  color: #ababab;
  margin-bottom: 5px;
  font-size: 12px;
}
.profileContainer .col-left .heading p strong{
  min-width: 110px;
  display: inline-block;
}
.renew_sidebar .heading {
  text-align: left;
  margin-bottom: 30px;
  font-size: 12px;
}

.renew_sidebar .heading p {
  color: #ababab;
  margin-bottom: 5px;
}
.renew_sidebar .heading p strong{
  min-width: 110px;
  display: inline-block;
}

.profile-right.white__box__with_border{
  padding: 60px;
}
.profile-right.white__box__with_border h1{
  margin-bottom: 30px;
}
.research_interests_fields{
  padding:30px 45px;
}
.how_did_you_learn{
  padding:30px 40px;
}
.special_skills_wrap{
  padding:30px 60px;
}
.heading .logout{
  margin-bottom: 30px;
  display: inline-block;
  background: none;
  box-shadow: none;
  border: none!important;
  text-shadow:none;
  color: #d40c01;
  padding: 0;
  font-weight: normal;
}
.registration_logout.heading {
  position: relative;
}
.registration_logout.heading .logout{
  margin-bottom: 0;
  position:absolute;
  right: 0px;
  top:0px;
}
.heading .logout:hover{
  color: #ec817b;
}
.renew__button{
  padding: 10px 20px 10px 10px;
}
.renew-buttons{
  display: inline-block;
  margin-top: 0;
  height: 35px;
  line-height: 35px;
  padding: 0;
  text-shadow: none;
  border: none!important;
  border-radius: 1px;
  background: #f08533;
  color: #fff;
  font-weight: 700;
  box-shadow: none;
  width: 100%;
  text-align: center;
}

.renew-buttons:hover,.renew-buttons:focus{
  color: #fff;
}