
.national_members {
  padding: 50px 0;
  text-align: center;
}

.national_members h2 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 50px;
  position: relative;
}

.national_members h2 span {
  display: inline-block;
  background: #fff;
  padding: 10px;
  position: relative;
}

.national_members h2 i {
  height: 1px;
  width: 100%;
  background: #aaa;
  position: absolute;
  left: 0;
  top: 32px;
}

.national_members .col {
  border-right: 1px solid #aaa;
  margin-bottom: 20px;
  min-height: 120px;
  position: relative;
}

.national_members .col:last-child {
  border: none;
}

.national_members .col span {
  display: block;
  position: absolute;
  left: 2%;
  bottom: -10px;
  width: 96%;
  height: 1px;
  background: #aaa;
}
