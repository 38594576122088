
.toggle_radios_popup {
  text-align: center;
}

.toggle_radio_a_popup {
  display: inline-block;
  color: #333;
  vertical-align: text-top;
  text-transform: uppercase;
}

.toggle_radios_popup span {
  color: #aaa;
  font-size: 30px;
  font-weight: 300;
  line-height: 30px;
  margin: 0 20px;
  vertical-align: sub;
}

.toggle_radio_a_popup:hover,
.toggle_radio_a_popup:focus {
  text-decoration: none;
}

.toggle_radio_a_popup i {
  color: #277532;
  font-size: 18px;
  margin-right: 10px;
}

.login_guest_form_popup {
  margin-top: 20px;
}

.login_guest_form_popup input[type="text"] {
  border-radius: 0;
  width: 100%;
  max-width: 300px;
  height: 30px;
}

.login_guest_form_popup input[type="password"] {
  height: 30px;
}

.login_guest_form_popup input[type="text"].error,
.login_guest_form_popup .signin_div_popup input.error {
  border-color: #E02828;
}

.login_guest_form_popup .signin_div_popup {
  text-align: left;
  margin-top: 10px;
  display: none;
}

.login_guest_form_popup .error_div_popup {
  margin: 10px 0;
  text-align: center;
  display: none;
  color: #E02828;
}

.error_div_spopup {
  margin: 10px 0;
  text-align: center;
  color: #E02828;
}

.login_guest_form_popup .warning_div_popup {
  margin: 10px 0;
  display: none;
  color: #c09853;
}

.login_guest_form_popup .signin_div_popup input {
  border-radius: 0;
}

.login_guest_form_popup .signin_div_popup .forgot {
  display: block;
  margin-top: 10px;
}

.login_guest_area_popup {
  display: table;
  margin: 0 auto;
}

#popup_registration_form {
  text-align: center;
}

.registration_div_popup input {
  border-radius: 0;
  height: 40px;
  box-sizing: border-box;
  width: 48%;
}

.registration_div_popup select {
  border-radius: 0;
  height: 40px;
  width: 48%;
  box-sizing: border-box;
}

.registration_div_popup .full_length {
  width: 97%;
}

.registration_div_popup .error {
  background: rgba(255, 81, 76, 0.25) !important;
}
