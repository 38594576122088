
.ebook_area {
  background: url(../images/blue-background-repeat-y-new.png) repeat-y;
  background-color: #39b3b8;
  color: #fff;
  padding: 40px 0;
  padding-bottom: 0px;
  position: relative;
}

.ebook_area.reverse{
  background: url(../images/orange-background-repeat-y-new.png) repeat-y;
}
.ebbok_area_wrapper {
  position: relative;
  z-index: 9;
}

.ebook_area h2 {
  text-transform: uppercase;
  font-size: 42px;
  line-height: 46px;
  font-weight: 300;
}

.ebook_area h5 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}

.icon_set {
  text-align: center;
}

.icon_set h4 {
  font-size: 16px;
  line-height: 20px;
  margin-top: 5px;
}

.findout_more {
  border-top: 1px solid #fff;
  text-align: center;
  padding: 20px;
  margin: 20px 0;
}

.findout_more a {
  color: #fff;
}

.ebook_bottom_area {
  background: #1e80ba;
  color: #fff;
  padding: 20px 0;
  width: 100%;
}

.ebook_bottom_area .btn-light {
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff;
}

.ebook_bottom_area strong {
  display: inline-block;
  padding: 0 20px;
}

.ebook_bottom_area .btn-secondary {
  text-transform: uppercase;
  color: #f16522;
  border: 1px solid #fff;
  background: #fff;
}

.ebook_bottom_area span {
  display: inline-block;
  max-width: 250px;
  vertical-align: top;
  margin-left: 10px;
}
