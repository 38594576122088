
/****************************************
*****************************************
DROPDOWN EXTENSION
This extension provides the use of
dropdowns in navigation.
*****************************************
****************************************/

/*=== DROPDOWN ========================*/
.navigation .nav-child{
  min-width: 180px;
  margin: 0;
  padding: 0;
  display: none;
  position: absolute;
  list-style: none;
  z-index: 98;
  white-space: nowrap;
}
.navigation-portrait .nav-child{
  width: 100%;
  position: static;
  left: 0;
}
.navigation .nav-child .nav-child{
  left: 100%;
}
.navigation .menu > li > .nav-child{
  border-top: solid 1px #f0f0f0;
}

/* Dropdown items */
.navigation .nav-child > li{
  width: 100%;
  float: left;
  clear: both;
  position: relative;
  text-align: left;
}
.navigation .nav-child > li > a{
  width: 100%;
  padding: 16px 20px;
  display: inline-block;
  text-decoration: none;
  float: left;
  font-size: 13px;
  color: #70798b;
  background-color: #fdfdfd;
  transition: color .3s, background .3s;
}
.navigation .nav-child > li:hover > a,
.navigation .nav-child > .focus > a{
  color: #A0D468;
}

/* Dropdown to left */
.navigation .nav-child{
  right: 0;
}
.navigation .nav-child > li > .nav-dropdown-left{
  left: auto;
  right: 100%;
}
.navigation-landscape .nav-dropdown-left > li > a{
  text-align: right;
}

/* Dropdown indentation (until the fifth level) */
.navigation-portrait .nav-child > li  > a{
  padding: 12px 20px 12px 30px;
}
.navigation-portrait .nav-child > li  > ul > li  > a{
  padding-left: 50px;
}
.navigation-portrait .nav-child > li  > ul > li  > ul > li  > a{
  padding-left: 70px;
}
.navigation-portrait .nav-child > li  > ul > li  > ul > li  > ul > li  > a{
  padding-left: 90px;
}
.navigation-portrait .nav-child > li  > ul > li  > ul > li  > ul > li  > ul > li  > a{
  padding-left: 110px;
}

/*=== SUBMENU INDICATORS ==============*/
.navigation .nav-child .submenu-indicator{
  right: 15px;
  top: 16px;
  position: absolute;
}
.navigation-portrait .submenu-indicator{
  right: 0;
  top: 0;
}
.navigation .nav-child .submenu-indicator .submenu-indicator-chevron{
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.navigation-portrait .nav-child .submenu-indicator .submenu-indicator-chevron{
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.navigation .nav-child > li:hover > a .submenu-indicator-chevron,
.navigation .nav-child > .focus > a .submenu-indicator-chevron{
  border-color: transparent #A0D468 #A0D468 transparent;
}

/* Submenu indicator to left */
.navigation-landscape .nav-dropdown-left .submenu-indicator{
  left: 10px;
}
.navigation-landscape .nav-dropdown-left .submenu-indicator .submenu-indicator-chevron{
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}
