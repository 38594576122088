$color_array: (#0b57a5,#93286e,#e4313c,#ea5c8a,#7d5524,#875173,#ea5c8a,#4b348f,#4b348f,#4b348f);

@for $i from 1 through 5 {
  @for $j from 1 through 4 {
    $random_keys: nth($color_array, random(10));

    .main_component_area .office_addresses .row:nth-child(#{$i}) .col-4:nth-child(#{$j}) .address_box {
      border-color: $random_keys;
    }
  }
}
