.stroll_to_area {
  position: relative;
  display: table;
  margin: 0 auto;
  text-align: center;

  img {
    max-width: inherit;
  }
}

.scroll_to_ebook {
  position: absolute;
  top: -20px;
  left: 0;
  z-index: 2;
  outline: none !important;
}
