.o-iframe {

  position: relative;
  max-width: 100%;
  height: auto;
  padding-bottom: percentage(9 / 16);
  // margin: 2rem 0;
  overflow: hidden;

  iframe,
  object,
  .map,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
