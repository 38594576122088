
.blackLogo{ display: none; }

.blackContent{
    .whiteLogo{ display: none !important; }
    .blackLogo{ display: block; }

     .nav-header .btn-primary {
        border: 1px solid #000 !important;
        background: none;
        color: #000 !important;
      
        @include hover {
          color: #212529;
          background-color: #fff !important;
          box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5);
        }
      }

      .navigation_wrapper .nav-header .nav-toggle {

        color: #000;
        cursor: pointer;
      
        &:after {
            color:#000 !important;
            border-color:#000;
          }

          &:before {
            background-color: #000 !important;
            box-shadow: 0 0.60em 0 0 #000 !important;
          }
      }

      //--------------------
      .main_banner_wrapper {
            color: #000 !important;
        }
      //--------------------
      
}

.hc-offcanvas-nav.nav-levels-overlap ul li.members-only-resources {
  background: #ef6422;
}