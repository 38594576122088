
.address_box {
  background: #fff;
  padding: 18px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  position: relative;
  border-left: 4px solid #0b57a5;
  margin-bottom: 30px;
  min-height: 160px;
}

.address_box h4 {
  color: #f16522;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
}

.address_box .address_line {
  height: 1px;
  background: #dfdfdf;
  width: 60px;
  margin: 10px 0 10px 0;
}

.address_box svg {
  color: #f16522;
}

.address_box p {
  margin-bottom: 5px;
}

.address_box a {
  margin-top: 5px;
  display: inline-block;
  color: #242323;
  font-weight: bold;
  font-size: 14px;
  vertical-align: text-bottom;
}
