.dashboard-container{
        max-width: 600px;
        padding: 30px 15px;
        margin:auto;
        .twitter-handle{
                font-size: 18px;
                color: #ccc;
                margin: 25px 0;
        }

        a{
                margin-bottom: 15px;
        }

        .btn-success{
                background:#e6720f;
                border-color: #e6720f;
        }
        .btn-success:hover,.btn-success:focus,.btn-success:active{
                background: #c5630d;
                border-color: #c5630d;
        }
        .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle{
                background: #c5630d;
                border-color: #c5630d;
        }
        .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus{
                 box-shadow:none;
        }
}