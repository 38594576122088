@mixin hover {
  &:active,
  &:focus,
  &:hover {
    @content;
  }
}

//
// Space elements equally vertically
//
@mixin u-space-vertically($space: $unit) {
  > * {
    margin: 0;
    &:not(:first-child) {
      margin-top: #{$space};
    }
  }
}
