
.btn {
  display: inline-block;
  padding: 1rem 2rem;
  transition-duration: 0.15s;
  transition-property: color background-color border-color box-shadow;
  transition-timing-function: ease-in-out;
  border: 1px solid transparent;
  border-radius: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;

  @include hover {
    text-decoration: none;
  }

  [class*=fa] {
    vertical-align: middle;
  }

  &-primary {
    background-color: $c-blue;
    color: $c-white;

    @include hover {
      background-color: darken($c-blue, 10%);
      color: $c-white;
    }
  }

  &-light {
    border: 1px solid $c-blue;
    background: none;

    @include hover {
      border: 1px solid darken($c-blue, 10%);
      background: none;
    }
  }

  &-secondary,
  &-warning {
    border-color: transparent;
    background-color: $c-orange;
    color: $c-white;

    @include hover {
      border-color: $c-orange;
      background-color: darken($c-orange, 10%);
      color: $c-white;
    }
  }
}
