:root {
  --home: #FF8600;
  --travel: #6f42c1;
  --tech: #007bff;
  --fashion: #e83e8c;
  --sports: #dc3545;
  --lifestyle: #13a774;
  --food: #17a2b8;
  --market: #ffc107
  }
.color-home {
	color: var(--home)!important
}
.color-travel {
	color: var(--travel)!important
}
.color-tech {
	color: var(--tech)!important
}
.color-fashion {
	color: var(--fashion)!important
}
.color-sports {
	color: var(--sports)!important
}
.color-lifestyle {
	color: var(--lifestyle)!important
}
.color-food {
	color: var(--food)!important
}
.color-market {
	color: var(--market)!important
}
.bg-home {
	background-color: var(--home)!important
}
.bg-travel {
	background-color: var(--travel)!important
}
.bg-tech {
	background-color: var(--tech)!important
}
.bg-fashion {
	background-color: var(--fashion)!important
}
.bg-sports {
	background-color: var(--sports)!important
}
.bg-lifestyle {
	background-color: var(--lifestyle)!important
}
.bg-food {
	background-color: var(--food)!important
}
.bg-market {
	background-color: var(--market)!important
}

.news_article{
  .entry-image{
    position: relative;
    text-align: center;
  }
  .entry-image img{
    width: 100%;
  }
  .entry-categories a {
    color: #fff!important;
    padding: 3px 5px;
    font-size: 10px;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    font-family: roboto,sans-serif;
  }
  .entry-categories {
      position: absolute;
      bottom: 10px;
      left: 10px;
      text-transform: capitalize;
  }
  .entry-title h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin: 20px 0;
    color: #444;
  }
  .entry-title a {
    color: #333;
    -webkit-transition: color .3s ease;
    -o-transition: color .3s ease;
    transition: color .3s ease;
  }
  .entry-title a:hover{
    color: #ff8600;
  }
  .entry-meta{
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .entry-meta li {
    float: left;
    font-size: 13px;
    line-height: 14px;
    margin: 0 10px 15px 0;
    color: #999;
  }
  .entry-meta li:before {
      content: '';
      margin-bottom: 3px;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: #ccc;
      display: inline-block;
      margin-right: 10px;
      opacity: .5;
  }
  .entry-meta li:first-child::before{
    display: none;
  }
  .entry-meta li a {
    color: #aaa;
    margin-left: 2px;
  }
  .entry-meta li a:hover {
    color: #ff8600;
  }
  .entry-content{
    color: #555;
    font-size: 14px;
    line-height: 22px;
  }
}

.news_article.spost{
  padding-top: 20px;
  border-top: 1px dashed #e5e5e5;
  margin-top: 20px;

  .entry-image {
    float: left;
    margin: 0 15px 0 0;
    text-align: center;
  }
  .entry-image a, .entry-image img {
    width: 48px;
    height: 48px;
  }
  .entry-title h4 {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
  }
  .entry-meta {
      margin: 8px 0 0 0;
  }
} 

.double-line, .line {
  clear: both;
  position: relative;
  width: 100%;
  margin: 60px 0;
  border-top: 1px solid #eee;
}
.line-xs {
  margin: 4px 0 20px;
  border-bottom: 3px solid #eee;
}
.line-xs.line-home {
  border-bottom-color: var(--home)!important;
}

.line-xs:after {
  top: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #eee;
  border-width: 8px;
  margin-left: -8px;
  z-index: 1;
  background: 0 0;
}
.line-xs.line-home:after {
  border-top-color: var(--home)!important;
}

.sidebar_title {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.latest_newsflash{
  padding: 30px 0;
  .news_article.spost:first-child {
      padding-top: 20px;
      border-top:none;
      margin-top: 0px;
  }
}