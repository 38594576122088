.calendar {
  margin: 0 0 60px;
  border:1px solid #dfdfdf;
}
.calendar header {
	padding: 0 52px;
	margin: 0 0 30px
}
.calendar header::after {
	content: "";
	display: table;
	clear: both
}
.calendar .total-events {
	margin: 42px 0 0;
	float: right;
	text-align: center;
	font-size: 16px;
	color: #9f9fad
}
.calendar .total-events strong {
	display: block;
	font-size: 31px;
	margin: 0 0 7px;
	line-height: 1;
	font-weight: 500;
	color: #e64614
}
.calendar h1 {
	text-transform: uppercase;
	font-size: 40px;
	font-weight: 900;
	margin: 0 0 30px
}
.calendar .body {
	background: #fff;
	border-radius: 4px;
	overflow: hidden
}
.btn-more {
	display: block;
	padding: 14px;
	border-radius: 0 0 4px 4px
}
.sort {
	font-size: 15px
}
.sort a+a {
	margin-left: 83px
}
.sort i {
	font-size: 12px;
	margin-left: 10px
}
.sort em {
	display: none;
	font-style: normal
}
.event-item {
	position: relative;
	overflow: hidden;
	padding: 40px 0
}
.event-item+.event-item {
	border-top: 1px solid #f4f5f9
}
.single .event-item {
	border-bottom: 1px solid #f4f5f9
}
.single .event-item .frame {
	padding-right: 220px
}
.event-item .date {
	float: left;
	width: 153px;
	text-align: center;
	color: #a0a0ad;
	font-weight: 300;
	font-size: 23px;
	text-transform: uppercase
}
.event-item .date strong {
	display: block;
	font-weight: 300;
	font-size: 43px;
	line-height: 1;
	margin: 0 0 10px
}
.event-item .time {
	float: left;
	width: 190px;
	color: #a0a0ad;
	font-size: 15px
}
.event-item .h {
	display: inline-block;
	vertical-align: top
}
.event-item .sep {
	display: block;
	margin: 14px auto;
	height: 14px;
	width: 1px;
	background: #cfcfd5
}
.event-item .frame {
	overflow: hidden;
	padding-right: 50px
}
.event-item .frame:first-child {
	padding-left: 52px
}
.event-item h2 {
	font-size: 26px;
	font-weight: 500;
	margin: 2px 0 18px;
	color: #e64614;
	letter-spacing: 1.5px
}
.event-item h2 a {
	color: inherit
}
.event-item .meta {
	display: block;
	font-size: 16px;
	color: #959db7
}
.event-item .meta a{
  color: #555;
}
.event-item .more {
	position: absolute;
	top: 50%;
	right: 48px;
	margin-top: -32px;
	color: #f4f5f9;
	font-size: 60px;
	line-height: 1;
	-webkit-transition: all .3s ease-out;
	-o-transition: all .3s ease-out;
	transition: all .3s ease-out
}
.event-item:hover .more {
	color: #e64614;
	right: 40px
}
.event-item .action {
	position: absolute;
	top: 0;
	right: 52px;
	width: 150px
}
.event-item .action::after {
	content: "";
	display: table;
	clear: both
}
.event-item .action .line {
	margin: 0 0 32px
}
.event-item .action .line::after {
	content: "";
	display: table;
	clear: both
}
.event-item .action .btn, .event-item .action .jcf-select {
	display: block
}
.event-item .price {
	float: left;
	margin: -4px 0;
	font-size: 31px;
	font-weight: 700;
	margin-left: -44px;
	text-align: right;
	width: 100px;
	color: #e64614
}
.event-item .count {
	float: right;
	margin: 0 10px 0 0;
	padding: 0 12px 0 0;
	text-align: center;
	width: 59px;
	border-left: 1px solid #dee1ec;
	font-size: 19px;
	line-height: 27px;
	font-weight: 700;
	color: #464c62;
	position: relative
}
.event-item .count .dec, .event-item .count .inc {
	position: absolute;
	right: 0;
	top: -5px;
	width: 12px;
	height: 12px;
	border: 6px solid transparent;
	content: "";
	border-color: transparent transparent #464c62 transparent
}
.event-item .count .dec {
	top: auto;
	bottom: -5px;
	border-color: #464c62 transparent transparent transparent
}