
.footer {
  padding-top: 50px;
  padding-bottom: 20px;
  background: url('../images/footer-banner.jpg') no-repeat;
  background-size: cover;
  color: $c-white;
}

.footer a {
  color: $c-white;

  @include hover {
    color: $c-orange;
  }
}

.footer h3 {
  color: $c-white;
  font-size: 20px;
  line-height: 24px;
  //margin-bottom: 30px;
  text-transform: uppercase;
  margin: 15px 0;
}

.footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer ul li {
  margin-bottom: 10px;
  width:100%;
}

.copyright {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 0;
}
