$scroll-factor: .5;

$header-height: 50px;

.body-wrapper {
  max-height: 100%;
  // overflow-x: hidden;
  // perspective: 1px;
  // perspective-origin: center top;
  // transform-style: preserve-3d;
}

.main_banner {
  position: relative;
  vertical-align: top;
  // transform-origin: center top;
  // transform:
  //   translateZ(-#{$scroll-factor * 2}px)
  //   scale(1 + $scroll-factor * 2);
  height: calc(100vh - 40px);
  min-height: 520px;
}

.home-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 40px);
  min-height: 520px;



  &-item {
    width: 100%;
    height: calc(100vh - 40px);
    min-height: 520px;
    background-size: cover;
  }
}


