.header {
  background: #fdfdfd;
}

.front .header_wrapper .row {
  margin: 0;
}

.front .header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: none;
}

.header_wrapper {
  padding: 20px;
}

.front #navigation1 {
  padding: 10px 20px 0 0;
}

.header_top {
  text-align: right;
  padding: 30px 0;
}

.front .header .btn-light {
  display: none;
}

.front .header .btn-primary {
  border: 1px solid #fff;
  color: #fff;
  background: none;
}

.front .header .btn-primary:hover {
  color: #212529;
  background-color: #fff;
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5);
}

.header_top .btn {
  text-transform: uppercase;
  min-width: 160px;
  margin-left: 10px;
}

.logo-wrap {
  display: block;
  // padding: 1rem 0;
  max-width: 250px;
  margin:auto;
  img {
    width: 100%;
  }
}

.navigation_buttton {
  font-size: 0.8rem;
}