// $scroll-factor: 0.5;
// .header,
// .navigation {
//   height: 200px;
//   position: relative;
//   vertical-align: top;
//   transform-origin: center top;
//   transform:
//     translateZ(-#{$scroll-factor * 2}px)
//     scale(1 + $scroll-factor * 2);
// }

.main_navigation {
  border-top: 1px solid $c-smoke;
  margin-bottom: 0px;
}

.main_navigation ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.main_navigation ul li {
  float: left;
}

.main_navigation ul >li > a {
  color: $c-gray;
  display: inline-block;
  vertical-align: middle;
  padding: 0 21px;
  letter-spacing: 1.5px;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 60px;
  z-index: 1;
}

.main_navigation ul>.item-101>a {
  padding-left: 0px;
}

.main_navigation ul>.item-108>a {
  padding-right: 0px;
}

.main_navigation ul>li>a:hover,
.main_navigation ul>li>a:focus,
.main_navigation ul>li.active>a {
  background: none;
  color: $c-blue;
}

#navigation1 {
  background: none;
  position: absolute;
  left: 0;
  top: 20px;
  z-index: 999;
}

.navigation_wrapper {
  position: relative;
}

.nav-header {
  float: none;
  text-align: right;
}

.nav-header .nav-toggle {
  display: inline-block;
  position: absolute; top:0; left:0;

  width: 30px;
  height: 50px;
  margin-top: 0;
  margin-left: 50px;
  padding: 6px 2px 0;
  
  color: $c-light-gray;
  cursor: pointer;

  .front & {
    color: $c-white;

    &:after {
      color: $c-white;
    }

    &:before {
      background-color: $c-white;
      box-shadow: 0 0.60em 0 0 $c-white;
    }
  }
}

.nav-header .nav-toggle1 {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  margin-top: 0;
  margin-left: 30px;
  padding: 6px 2px 0;
  color: $c-light-gray;
  cursor: pointer;

  .front & {
    color: $c-white;

    &:after {
      color: $c-white;
    }

    &:before {
      background-color: $c-white;
      box-shadow: 0 0.60em 0 0 $c-white;
    }
    &:before {
      background-color: $c-white;
      box-shadow: 0 0.60em 0 0 $c-white;
    }
  }
}

.front .nav-header .btn-primary {
  border: 1px solid $c-white;
  background: none;
  color: $c-white;

  @include hover {
    color: #212529;
    background-color: $c-white;
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5);
  }
}

#navigation1{
  .nav-header{
    margin-right: 40px;
  }
}


body .hc-offcanvas-nav .nav-wrapper.nav-wrapper-1{
  overflow-x:hidden;
}

.innerSideMenu{
 
   padding: 0px;
   margin-bottom: 50px;
   
   li:first-child{
    background:#c1511b;
    padding: 14px 13px;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
   }
   li{
     list-style:none;
     a{ 
      background: #ff8600;
      display: block;
      padding: 14px 13px;
      color: #fff;
      border-top: #ea6e32 1px solid;
     }
     a:hover{
         background:#ef6422;
      }
   }

}