.membership_search_page{
    .padding-top-60{
      padding-top: 60px!important;
    }
    .padding-60{
      padding: 20px 0!important;
    }
    .col-left{
      position: relative;
      min-height: 500px;
       background:#f7f7f7;
       padding-right:0;
       padding-bottom:10px;
      .heading{
        padding-bottom:20px;
        h4{ color:#515151; margin-bottom: 0rem;}
        p{color:#ababab; margin-bottom: 0rem;}
      }
      h6{ color:#515151; font-weight: bold; padding-left:15px; line-height: 0.5px; margin-top:2rem;}
      .nav{
        a{ color:#515151;
          .fas{ width:25px;}
        }
        a.nav-link {
            padding: .5rem 0rem; padding-left: 15px;
            border-top: #ccc 1px solid;
            border-radius:0;
        }
        a:last-child{ border-bottom:#ccc 1px solid; }
        a.active{
          background: #fff;  border-left:#ccc 1px solid;
          span{
            position: absolute;
            width: 10px;
            right: 0;
            height: 38px;
            margin: -8px;
            background: #fff;
          }
        }
      }
    }

    .col-right{
       padding: 10px 20px;
       padding-left: 30px;
       h2{ font-size: 1.5rem; text-transform:uppercase; color:#ccc;
        
         label{
           font-size: 1rem; float: right;
         }
      }
       

       .info_line { margin-bottom: 10px; }
    
    }
}

.membership_search_page .col-left:before{
  content: "";
  width: 1000px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: #f7f7f7;
  z-index: -1;
}

.membership_sidebar{
  .heading{
    text-align:left;
  }
  .sidebar_blocks{
    border-top:1px solid #dfdfdf;
    padding:20px 0;
  }
  h5{
    font-size:16px;
    font-weight:bold;
  }
  .sidebar_block {
    padding-left:10px;
    ul{
      list-style-type:none;
      margin-left:30px;
      padding:0;
    }
    li{
      margin-top:5px;
      
    }

    a{
      span{
        color:#aaa;
      }
    }
    
  }
  
}

.ajaxLoader{
  display:none;
}
.cssload-container{
	position:fixed;
  z-index:9999;
  background:rgba(255,255,255,.5);
  width:100%;
  height:100%;
  left:0;
  top:0;
}
	
.cssload-whirlpool,
.cssload-whirlpool::before,
.cssload-whirlpool::after {
	position: absolute;
	top: 50%;
	left: 50%;
	border: 1px solid rgb(204,204,204);
	border-left-color: rgb(0,0,0);
	border-radius: 974px;
		-o-border-radius: 974px;
		-ms-border-radius: 974px;
		-webkit-border-radius: 974px;
		-moz-border-radius: 974px;
}

.cssload-whirlpool {
	margin: -24px 0 0 -24px;
	height: 49px;
	width: 49px;
	animation: cssload-rotate 1150ms linear infinite;
		-o-animation: cssload-rotate 1150ms linear infinite;
		-ms-animation: cssload-rotate 1150ms linear infinite;
		-webkit-animation: cssload-rotate 1150ms linear infinite;
		-moz-animation: cssload-rotate 1150ms linear infinite;
}

.cssload-whirlpool::before {
	content: "";
	margin: -22px 0 0 -22px;
	height: 43px;
	width: 43px;
	animation: cssload-rotate 1150ms linear infinite;
		-o-animation: cssload-rotate 1150ms linear infinite;
		-ms-animation: cssload-rotate 1150ms linear infinite;
		-webkit-animation: cssload-rotate 1150ms linear infinite;
		-moz-animation: cssload-rotate 1150ms linear infinite;
}

.cssload-whirlpool::after {
	content: "";
	margin: -28px 0 0 -28px;
	height: 55px;
	width: 55px;
	animation: cssload-rotate 2300ms linear infinite;
		-o-animation: cssload-rotate 2300ms linear infinite;
		-ms-animation: cssload-rotate 2300ms linear infinite;
		-webkit-animation: cssload-rotate 2300ms linear infinite;
		-moz-animation: cssload-rotate 2300ms linear infinite;
}



@keyframes cssload-rotate {
	100% {
		transform: rotate(360deg);
	}
}

@-o-keyframes cssload-rotate {
	100% {
		-o-transform: rotate(360deg);
	}
}

@-ms-keyframes cssload-rotate {
	100% {
		-ms-transform: rotate(360deg);
	}
}

@-webkit-keyframes cssload-rotate {
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes cssload-rotate {
	100% {
		-moz-transform: rotate(360deg);
	}
}

.modal-backdrop-local{
  position:fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background:#000;
  opacity:0.5;
  display:none;
}
#member-keyword-search{
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  padding-left:10px;
}
.membership_sidebar .search_block{
  padding-left:20px;
  padding-right:20px;
}
.sidebar-toggle-link {
  position: relative;
  cursor: pointer;
}
.sidebar-toggle-link span{
  font-size: 24px;
  position:absolute;
  right: 10px;
  top: -7px;
}
.collapsed .sidebar-toggle-link span{
  top:0;
}
.sidebar-toggle-link span .fa-sort-up{
  display: none;
}
.collapsed .sidebar-toggle-link span .fa-sort-down{
  display: none;
}
.collapsed .sidebar-toggle-link span .fa-sort-up{
  display: inline;
}

.collapsed .sidebar-toggle-link span .fa-sort-down{
  display: none;
}

.collapsed .sidebar_block{
  display: none;
}