
.video_area {
  padding: 50px 0;
  background: url(../images/video-bg.jpg) no-repeat;
  background-size: cover;
}

.video_area h3 {
  font-size: 32px;
  line-height: 38px;
  margin-top: 30px;
  font-weight: 300;
  margin: 0;
  text-transform: uppercase;
}

.video_area p {
  line-height: 22px;
}

.video_area .text_area_box {
  background: #fff;
  padding: 40px;
  position: relative;
}

@include media('>=md') {
  .video_area .text_area_box:after {
    position: absolute;
    top: 20px;
    left: -20px;
    width: 34px;
    height: 58px;
    background: url(../images/left-arrow.png) no-repeat;
    content: '';
  }
}
