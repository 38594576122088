@media( max-width:1900px ){

}

@media( max-width:1199px ){

}


@media (max-width: 991px){
    
}

//========372 Breack Point=========
@media( max-width:768px ){ 
    
}
//==================================
@media( max-width:500px ){ 
    
    //---------Heder and Nav-------------------
    .nav-header{
        .nav-toggle {top: -20px;margin-left: 20px; }
    }
    .logo-wrap { max-width:125px; }
    #navigation1{
        .nav-header {
            margin-right: 0px;
            margin-top: -10px;
            float: right;
            .btn{ padding: 10px 20px; }
            .btn-primary {
                width: 53px;
                overflow: hidden;
                .fa-user{
                    padding-right: 23px;
                }
                .fa-sign-out-alt{
                    padding-right: 23px;
                }
            }
            
        }
    }
    .site{
        #navigation1{
            .nav-header {
                margin-right: 18px;
            }
        }
        .inner_banner {
            padding: 160px 0;
            h1 { font-size: 30px; }
        }
        .innerSideMenu{ display: none;}

    }

    //---------MoveUpContent Content--------------------
    .movePageUp{
        .main_article_area {
            padding: 30px;
        }
    }
    //---------Profile Edit--------------------
    .profileContainer{
        .col-right {
            padding:20px !important;
        }

    }
    //-----------------------------
    .login, .new-membership-application{
        .white_box_header { padding: 0 3px; }
        .white_box_tabs a { min-width:150px; margin-right:0px; }

    }
    //-----------------------------
    .membership_search_page{
        .col-right {
            padding-left:2px;
        }
    }
    //-----------------------------
    .front .main_banner .text_area {
        margin-top: 30vh;
    }
    //-----------------------------
}

