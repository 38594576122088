
.news_box {
  max-width: 320px;
  margin: 0 auto 1rem;
  overflow: hidden;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  position: relative;
}

.news_box h4 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 300;
  margin: 0;
  padding-right: 20px;
}

.news_box p {
  padding-right: 20px;
}

.news_box .news_bottom_line {
  height: 1px;
  background: #dfdfdf;
  max-width: 50px;
  margin: 5px 0 10px 0;
}

.news_box .img_area {
  width: 100%;
  height: 220px;
  background-size: cover;
  background-repeat: no-repeat;
}

.news_box .btn_area {
  text-align: right;
}

.news_box .btn_area .btn {
  padding: 10px 20px;
}

.news_box .news_info {
  padding: 20px 0px 0 20px;
}

.news_box .date_area {
  background: #f16522;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  top: 0px;
  left: 10px;
}

.news_box .date_area .date {
  display: block;
  font-size: 24px;
  line-height: 28px;
}

.news_box .date_area .month {
  display: block;
  font-size: 18px;
  line-height: 22px;
}

.news_box .date_area .year {
  display: block;
  background: $c-blue;
  font-size: 20px;
  line-height: 26px;
  padding: 0 5px;
}


.news_area {
  padding: 50px 0;
}

.news_area h3 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 30px;
  text-transform: uppercase;
  text-align: center;
}

.news_area .button_area {
  text-align: center;
}

.news_area .button_area .btn {
  text-transform: uppercase;
  border-color: #f16522;
  margin-top: 30px;
}
