.filter_search_bar_area_wrap{
     margin-top:60px;
}

.filter_search_bar_area{
    margin-top: 40px;
    background: #fff;
    .nav-pills .nav-link{
        border-radius: 0px;
    }
    .nav-link{
        color: #777;
        padding: 15px 20px;
        border-bottom:1px solid #dfdfdf;
        i{
            float: right;
        }
    }
    .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
        background: #e6720f;
    }
    #filter_sidebar_tab{
        min-height: 320px;
        border-right: #ccc 1px solid;
    }

    #filter_sidebar_tabContent{
        padding: 10px 40px;
        min-height: 320px;
        //border-left:1px solid #dfdfdf;
        h5{
            text-transform: lowercase;
            margin-bottom: 20px;
        }
        .row{
            margin: 0;
        }
    }
    .filter_search_bar_area_footer{
        padding: 10px 20px;
        border-top:1px solid #dfdfdf;
        text-align: right;
        display: none;
        .btn{
            border-radius: 0.25rem;
            padding: 5px 20px;
            i{
                transform: scaleX(-1);
                margin-left: 5px;
                vertical-align: middle;
            }
        }
    }

    .filter_search_bar_area_main{
        display: none;
    }

    .filter_search_bar_area_header{
        padding:20px;
       
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
		-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
        cursor: pointer;
        h4{
            margin-bottom: 0;
        }

        .filter_title_bar{
            font-size: 20px;
            font-weight: 600;
            float: left;
            i{
                color: #e6720f;
                margin-right: 5px;
            }
            .right_side_icon{
                color: #333;
                margin-right: 5px;
                font-size: 16px;
            }
            .fa-chevron-down.right_side_icon{
                display: none;
            }
        }
        
        .filter_count_bar{
            float: right;
            font-size: 16px;
            font-weight: normal;
        }

        //------------------------------
        .selected-attr{
            padding: 0;
            display: inline-block;
            margin-left: 20px;
            max-width: 80%;
            a{
                background: #231f20;
                padding: 4px 15px;
                color: #dcc37e;
                border-radius: 5px;
                font-size: 14px;
                width: auto;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                text-align: center;
                &::before {
                    content: "x";
                    //font-family: "Font Awesome 5 Free";
                    font-weight: 500;
                    margin-left: 10px;
                    float: right;
                }
            }
        
            a.clear-all{
                color: #ffffff;
                background: #e6720f;
                font-weight: 600;
                 &::before {
                     display: none;
                 }
            }
        }
        //------------------------------
    }

    .show_hide{
        display: none;
    }
}

.show-more-btn{
    color: #e6720f;
    &:hover{
        color: #e6720f;  
    }
}

.filter_search_bar_area.active{
    border-bottom: #ccc 1px solid;

    .filter_search_bar_area_main{
        display: block;
        border-right: #ccc 1px solid;
        border-left: #ccc 1px solid;
    }
    .filter_search_bar_area_footer{
        display: block;
    }
    .filter_search_bar_area_header{
        .fa-chevron-down.right_side_icon{
            display: inline-block;
        }
        .fa-chevron-right.right_side_icon{
            display: none;
        }
    }
}







.single_attr{
    margin-bottom: 10px;
}

/* Hide the browser's default checkbox */
.customCbox{
    display: block;
    margin-left: 15px;
    margin-top: 1px;
    font-size: 14px;
}

.customCbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
   // background-color: #eee;
   border: 3px solid #e6720f;
  }
  
  /* On mouse-over, add a grey background color */
  .customCbox:hover input ~ .checkmark {
    background-color: #fff;
  }
  
  /* When the checkbox is checked, add a blue background */
  .customCbox input:checked ~ .checkmark {
    background-color: #e6720f;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .customCbox input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .customCbox .checkmark:after {
    left: 6px;
    top: 3px;
    width: 7px;
    height: 12px;
    border: solid #fff;;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
//------------------------------
.search_bar_area{
	padding:20px 0;
	position: relative;
	input{
		width: 450px;
		height: 50px;
		box-sizing: border-box;
		padding: 10px;
		padding-left: 40px;
		border: 0px;
		-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
		-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
		box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
		outline:none!important;
	}
	input:focus{
		-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
		-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
		box-shadow: 0px 0px 5px 0px rgba(131, 189, 228, 0.15);
	}
	i.searchicon{
		position: absolute;
		top: 34px;
		left: 10px;
		font-size: 24px;
	}
	i.clear_search{
		position: absolute;
		top: 34px;
		left: 420px;
		font-size: 24px;
		cursor: pointer;
	}

	p{
		margin-top: 5px;
		font-size: 14px;
		line-height: 18px;
		color: #999;
		strong{
			font-weight: bold;
		}
	}
}

//------------------------------
.membership_search_page .col-right {
   padding: 0 15px!important;
    padding-left: 15px !important;
}

//------------------------------